// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

// colors
$color-main:            #1d1e22 !default;
$color-light:           #88888c !default;
$color-primary:         #f40050 !default;
$color-secondary:       #46bd0b !default;
$color-neutral:         #a1a2a5 !default;
$color-info:            #5f6169 !default;
$color-white:           #fff !default;
$color-black:           #000 !default;
$color-success:         #46bd0b !default;
$color-danger:          #f40050 !default;
$color-gray:            #cfcfd9 !default;
$bg-dark:               #28292f !default;

// body
$body-bg:               $color-white !default;
$body-color:            $color-main !default;

// links
$link-color:            $color-main !default;
$link-decoration:       none !default;
$link-hover-color:      $color-primary !default;
$link-hover-decoration: none !default;

// selection
$selection-bg:          $color-primary !default;
$selection-color:       $color-white !default;

// placeholder
$placeholder-color:     $color-light !default;

//fonts
$font-size--root:       16px !default;
$font-size--base:       14px !default;
$line-height--base:     1.375em !default;
$font-size--h1:         26px !default;
$font-size--h2:         24px !default;
$font-size--h3:         22px !default;
$font-size--h4:         20px !default;
$font-size--h5:         18px !default;
$font-size--h6:         16px !default;
$font-roboto:           Roboto, arial, sans-serif !default;
$font-raleway:          Raleway, arial, sans-serif !default;

// sizez
$site-size:             1700px !default;

// media quaries
$breakpoints:           (
    'phone':            20em, //320px
    'tablet-small':     30em, //480px
    'mobile-nav':       48em, //620px
    'tablet':           48em, //768px
    'desktop':          64em, //1024px
    'desktop-large':    80em  //1280px
) !default;

// bem
$bem:                   (
    'debug':            false,
    'separator':        (
        'element':      '__',
        'modifier':     '--',
        'state':        '-',
    ),
    'namespace':        (
        'component':    'c',
        'hack':         '_',
        'object':       'o',
        'scope':        's',
        'test':         'qa',
        'theme':        't',
        'utility':      'u',
    )
) !default;

// Helpers
$transition-speed:      0.3s !default;
$box-shadow:            0 0 6px rgba(35, 31, 32, 0.11) !default;
$border-radius:         3px !default;
