@include b(flag) {
    display: flex;
    padding: 5px;
    color: $color-white;
    background-color: rgba($color-black, 0.8);
    font-size: 11px;
    line-height: 1;
    align-items: center;
    @include m(primary) {
        color: $color-primary;
        font-family: $font-raleway;
        font-size: 10px;
        font-weight: 900;
        line-height: 9px;
        text-transform: uppercase;
    }
    @include m(checkbox) {
        position: absolute;
        right: 5px;
        top: 5px;
        z-index: 10;
        padding: 0;
        background: none;
    }
    @include e(icon) {
        margin-right: 5px;
    }

    &:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    &:not(:first-child) {
        margin-left: 1px;
    }
}

@include b(flag-group) {
    display: flex;
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 10px;
}

@include b(flag-action) {
    position: absolute;
    z-index: 2;
    top: 10px;
    left: 0;
    @include on-event() {
        .flag {
            background-color: $color-primary;
        }
    }

    .flag {
        &:first-child {
            border-radius: 0;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
        }
        @include media('<tablet-small') {
            padding: 8px;
            font-size: 16px;
        }
    }

    .dropdown__content--center,
    .dropdown__content--center .triangle {
        @include media('<tablet-small') {
            left: 2px;
            margin-top: 0;
            transform: none;
        }
    }

    .dropdown__content--center .triangle {
        @include media('<tablet-small') {
            left: 10px;
            transform: none;
        }
    }
}
