.select2-selection--single {
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 4px;

    .select2-selection__rendered {
        color: #444;
        line-height: 28px;
    }

    .select2-selection__clear {
        cursor: pointer;
        float: right;
        font-weight: bold;
    }

    .select2-selection__placeholder {
        color: #999;
    }

    .select2-selection__arrow {
        height: 26px;

        position: absolute;

        top: 1px;
        right: 1px;

        width: 20px;

        b {
            border-color: #888 transparent transparent transparent;
            border-style: solid;
            border-width: 5px 4px 0 4px;

            height: 0;
            left: 50%;

            margin-left: -4px;
            margin-top: -2px;

            position: absolute;

            top: 50%;
            width: 0;
        }
    }
}

&[dir="rtl"] {
    .select2-selection--single {
        .select2-selection__clear {
            float: left;
        }

        .select2-selection__arrow {
            left: 1px;
            right: auto;
        }
    }
}

&.select2-container--disabled {
    .select2-selection--single {
        background-color: #eee;
        cursor: default;

        .select2-selection__clear {
            display: none;
        }
    }
}

&.select2-container--open {
    .select2-selection--single {
        .select2-selection__arrow {
            b {
                border-color: transparent transparent #888 transparent;
                border-width: 0 4px 5px 4px;
            }
        }
    }
}
