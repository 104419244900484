// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

// font-face
@mixin font-face($font-family, $file-path, $font-weight, $font-style) {
    @font-face {
        font-family: $font-family;
            src: url('#{$file-path}.woff2') format('woff2'),
                 url('#{$file-path}.woff') format('woff');
            font-weight: $font-weight;
            font-style: $font-style;
        }
}

// placeholder
@mixin placeholder {
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder {@content;opacity: 1}
    &::-moz-placeholder {@content;opacity: 1}
    &:-ms-input-placeholder {@content}
    &:focus::-webkit-input-placeholder {color: transparent;}
    &:focus:-moz-placeholder {color: transparent;}
    &:focus::-moz-placeholder {color: transparent;}
    &:focus:-ms-input-placeholder {color: transparent;}
}

// selection
@mixin selection {
    ::-moz-selection {@content;}
    ::selection {@content;}
}

// justify align
%justify {
    font-size: 0;
    text-align: justify;
    text-justify: newspaper;
    &::after {
        width: 100%;
        height: 0px;
        visibility: hidden;
        overflow: hidden;
        content: '';
        display: inline-block;
    }
}

// underline link
%underline {
    position: relative;
    &::before {
        content: '';
        transition: transform .2s;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        height: 1px;
        background-color: #000;
        transform: scaleX(0);
    }
    &:hover {
        &::before {
            transform: scaleX(1);
        }
    }
}

// separator
%sep {
    position: relative;
    padding: 0 10px;
    &::after {
        position: absolute;
        content: '';
        top: 50%;
        transform: translateY(-50%);
        background-color: #fff;
        width: 1px;
        height: 11px;
        left: 0;
    }
    &:first-child {
        padding-left: 0;
        &::after {
            display: none;
        }
    }
}

// clearfix
%cf {
    &::before,
    &::after {
        display: table;
        content: "";
    }
    &::after {
        clear: both;
    }
}

// hidden text
%t-hide {
    overflow: hidden;
    padding: 0; /* 1 */
    text-indent: 101%;
    white-space: nowrap;
}

// visual hidden
%v-hide {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

// event mixin
@mixin on-event($self: false) {
    @if $self {
        .no-touchevents &,
        .no-touchevents &:hover,
        .no-touchevents &:active,
        .no-touchevents &:focus {
            @content;
        }
    } @else {
        .no-touchevents &:hover,
        .no-touchevents &:active,
        .no-touchevents &:focus {
            @content;
        }
    }
}

//aspect-ratio
@mixin aspect-ratio($width, $height, $selector: "> :first-child") {
    &:before {
        display: block;
        content: '';
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }
    #{$selector} {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
}
// Helper function
// Return null rather than throwing an error if index is outside list range.
@function nth-or-null($list, $index) {
  @return if(length($list) >= $index, nth($list, $index), null);
}

// icon
@mixin icon($position: before, $icon: false) {
    @if $position == both {
        $position: 'before, &::after';
    }
    // Either a :before or :after pseudo-element, or both, defaulting to :before
    &::#{$position} {
        @if $icon {
            // A particular icon has been specified
            content: "#{map-get($icons, $icon)}";
        }
        // Include any extra rules supplied for the pseudo-element
        @content;
    }
}

@mixin triangle($size, $color, $direction) {
    height: 0;
    width: 0;
    border-color: transparent;
    border-style: solid;
    border-width: $size / 2;

    @if $direction == up {
      border-bottom-color: $color;
    } @else if $direction == right {
      border-left-color:   $color;

    } @else if $direction == down {
      border-top-color:    $color;

    } @else if $direction == left {
      border-right-color:  $color;
    }
}


@mixin bordered-triangle($size, $border-width, $color, $border-color, $direction, $alt: false) {
  $back-size: $size + ($border-width * 2);
  position: relative;

  min-width: $size;
  min-height: $size;

  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
  }

  &:after {
     @include triangle($size, $color, $direction);
  }

  &:before {
    @include triangle($back-size, $border-color, $direction);
    $mid-border-width: ($border-width / 2);
    $border-width-and-mid: $border-width + $mid-border-width;

    @if ($alt == true) {
      $mid-border-width: 0;
      $border-width-and-mid: $border-width * 2;
    }

    @if ($direction == up) or ($direction == down) {
      margin-left: - $border-width;

      @if ($direction == down) {
        margin-top: -$mid-border-width;
      } @else {
        margin-top: - $border-width-and-mid;
      }

    } @else if ($direction == right) or ($direction == left) {
      margin-top: - $border-width;

      @if ($direction == right) {
        margin-left: - $mid-border-width;
      } @else {
        margin-left: - $border-width-and-mid;
      }
    }
  }

}
