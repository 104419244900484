// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/* container */
@include b(g-container) {
    clear: fix-legacy;
    max-width: $site-size;
    margin: auto;
    padding: 0 10px;
}

@include b(bg-main) {
    background-image: url('%=static=%general/bg-main-top-left.png'), url('%=static=%general/bg-main-top-right.png'), url('%=static=%general/bg-main-bottom-left.png'), url('%=static=%general/bg-main-bottom-right.png');
    background-repeat: no-repeat;
    background-position: left top, right top, left bottom, right bottom;
    background-size: 14%, 29%, 20%, 9%;
    @include media('>=1920px') {
        background-size: 270px 170px, 551px 575px, 517px 520px, 171px 155px;
    }
    @include media('height<=900px') {
        background-image: url('%=static=%general/bg-main-top-left.png'), url('%=static=%general/bg-main-top-right.png');
    }
}

.m-visible {
    display: none;
    @include media('<mobile-nav') {
        display: block;
    }
}

.flag-icon-en {
    background-image: url('%=static=%assets/language/us.svg');
}

.is-hidden {
    display: none;
}

.blockUI.blockMsg.blockElement {
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    border: 0 !important;
    background-color: transparent !important;
    font-size: 0 !important;
}
div.blockOverlay {
    background-color: rgba(255, 255, 255, 0.6) !important;
    cursor: wait;
}
.blockUI {
    opacity: 1 !important;
}

.empty-content {
    margin-top: 20px;
    font-weight: 700;
    text-transform: uppercase;
}
.error-page svg {
    display: block;
    width: 200px;
    height: 200px;
    margin: auto;
}
.error-page__text {
    margin-top: 15px;
    color: $color-black;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
}
