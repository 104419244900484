@include b(cards) {
    @include e(list) {
        display: flex;
        margin: -20px -10px 0;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: center;
        @include media('<tablet') {
            margin: -10px -5px 0;
        }
        @include media('<tablet-small') {
            margin: -6px -3px 0;
        }
    }
    @include e(item) {
        width: 20%;
        position: relative;
        padding: 20px 10px 0;
        @include media('<1200px') {
            width: 25%;
        }
        @include media('<desktop') {
            width: 33.33%;
        }
        @include media('<tablet') {
            width: 50%;
            padding: 10px 5px 0;
        }
        @include media('<tablet-small') {
            width: 100%;
            padding: 6px 3px 0;
            .card__col:first-child {
                .card__col:first-child {
                    display: none;
                }
                .card__col {
                    margin: 0;
                }
            }
        }
        @include m(small) {
            width: 14.28%;
            @include media('<1200px') {
                width: 16.66%;
            }
            @include media('<desktop') {
                width: 20%;
            }
            @include media('<900px') {
                width: 25%;
            }
            @include media('<tablet') {
                width: 33.33%;
                padding: 10px 5px 0;
            }
            @include media('<tablet-small') {
                width: 50%;
                .card__action {
                    display: none;
                }
            }
        }
        @include m(medium) {
            width: 16.66%;
        }
        @include m(big) {
            width: 25%;
            @include media('<1100px') {
                width: 33.33%;
            }
        }
        @include m(large) {
            width: 33.33%;
        }
        @include m(videos) {
            @include media('<tablet-small') {
                width: 50%;
            }
        }
    }
}

@include b(card) {
    display: block;
    position: relative;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    box-shadow: $box-shadow;
    @include on-event() {
        outline: 0;
        box-shadow: 0 0 16px 3px rgba(35, 31, 32, 0.4);
        .flag-group {
            display: none;
        }
        .card__sub {
            border-color: $color-black;
        }
        .card__footer {
            border-color: $color-black;
            color: $color-white;
            background-color: $color-black;
        }
    }
    @include m(primary) {
        .card__footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .card__title {
            text-transform: uppercase;
        }
        .card__action {
            margin-top: 0;
        }
        @include on-event() {
            .card__footer {
                border-color: $color-primary;
                background-color: $color-primary;
            }
            .card__action {
                color: $color-white;
            }
        }
    }
    @include e(content) {
        display: block;
        overflow: hidden;
        position: relative;
        @include aspect-ratio(320, 180, '.card__img');
        @include m(land) {
            @include aspect-ratio(222, 242, '.card__img');
        }
        @include m(small) {
            @include aspect-ratio(222, 162, '.card__img');
        }
        @include m(medium) {
            @include aspect-ratio(261, 285, '.card__img');
        }
        @include m(medium-s) {
            @include aspect-ratio(261, 186, '.card__img');
        }
        @include m(masonry) {
            height: 0;
            &::before {
                display: none;
            }
            // .card__img {
            //     position: static;
            //     display: block;
            // }
        }
    }
    @include e(footer) {
        display: block;
        overflow: hidden;
        padding: 10px;
        border: 1px solid $color-gray;
        border-top: 0;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
        color: $color-main;
        background-color: $color-white;
        @include media('<tablet-small') {
            padding: 5px;
        }
    }
    @include e(action) {
        display: flex;
        margin-top: 10px;
        color: $color-neutral;
        justify-content: space-between;
        align-items: center;
        @include media('<tablet-small') {
            margin-top: 5px;
        }
    }
    @include e(col) {
        display: inline-flex;
        line-height: 1;
        align-items: center;
        &:not(:first-child) {
            margin-left: 12px;
        }
    }
    @include e(icon) {
        margin-right: 4px;
        font-size: 15px;
        @include m(primary) {
            color: $color-primary;
        }
    }
    @include e(text) {
        margin-top: 2px;
        font-size: 12px;
    }
    @include e(title) {
        display: block;
        overflow: hidden;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.3;
        white-space: nowrap;
        text-overflow: ellipsis;
        @include media('<tablet-small') {
            font-size: 12px;
        }
        .masonry & {
            max-height: 35px;
            white-space: normal;
            text-overflow: inherit;
            @include media('<tablet-small') {
                max-height: 30px;
            }
        }
    }
    @include e(sub) {
        display: block;
        margin: 10px -10px -5px;
        padding: 5px 10px 0;
        border-top: 1px solid $color-gray;
    }
    @include e(link) {
        display: inline-flex;
        color: $color-primary;
        font-size: 12px;
        line-height: 1;
        vertical-align: middle;
        @include on-event() {
            text-decoration: underline;
        }
    }
    @include e(images) {
        display: flex;
        width: 100%;
        padding: 2px;
        background-color: $color-black;
        justify-content: flex-start;
        img {
            display: block;
            width: 103px;
            min-width: 1px;
            height: 58px;
            flex-grow: 1;
            @include media('<tablet-small') {
                height: 40px;
            }
            &:not(:first-child) {
                margin-left: 2px;
            }
        }
    }
    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
    }
}

@include b(masonry) {
    &-sizer,
    &-item {
        width: 16.66%;
        @include media('<1200px') {
            width: 20%;
        }
        @include media('<desktop') {
            width: 25%;
        }
        @include media('<tablet') {
            width: 33.33%;
        }
        @include media('<tablet-small') {
            width: 50%;
        }
        &.cards__item--big {
            width: 25%;
            @include media('<tablet') {
                width: 33.33%;
            }
            @include media('<540px') {
                width: 50%;
            }
        }
    }
}
.preview-progress {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    left: 0;
    width: 0;
    height: 3px;
    background: $color-primary;
    transition: width 1.2s;
}
.preview-progress.is-full {
    width: 100%;
}
.is-hidden-thumbs .cards__list,
.is-hidden-thumbs .heading__title,
.is-hidden-thumbs .heading__icon,
.is-hidden-thumbs .pagination,
.is-hidden-thumbs .btn-group__item:first-child {
    display: none;
}
[data-hide-thumbs='true'] {
    font-size: 11px;
    &::before {
        display: inline-block;
        vertical-align: middle;
        content: attr(data-text);
    }
}
.is-hidden-thumbs [data-hide-thumbs='true'] {
    &::before {
        content: attr(data-text-expand);
    }
}
