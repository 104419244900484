.select2-selection--multiple {
    background-color: $color-white;
    border: 1px solid $color-gray;
    border-radius: 2px;
    cursor: text;
    min-height: 42px;
    font-size: 0;

    .select2-selection__rendered {
        box-sizing: border-box;
        list-style: none;
        padding: 10px;
        width: 100%;
        display: block;
        margin: -7px 0 0;
        li {
            list-style: none;
        }
    }

    .select2-selection__placeholder {
        color: $placeholder-color;
        margin-top: 5px;
        float: left;
    }

    .select2-selection__clear {
        cursor: pointer;
        float: right;
        font-weight: 700;
        margin-top: 5px;
        margin-right: 10px;
    }

    .select2-selection__choice {
        box-shadow: $box-shadow;
        border-radius: $border-radius;
        border: 1px solid $color-gray;
        background-color: $color-white;
        border-radius: 3px;
        cursor: default;
        float: left;
        margin-right: 5px;
        margin-top: 7px;
        padding: 0 7px;
        color: $color-light;
        font-family: $font-raleway;
        font-size: 11px;
        font-weight: 700;
        text-transform: uppercase;
        @include on-event() {
            background-color: $color-primary;
            color: $color-white;
            border-color: $color-primary;
            .select2-selection__choice__remove {
                color: $color-white;
            }
        }
    }

    .select2-selection__choice__remove {
        font-size: 17px;
        cursor: pointer;
        float: right;
        color: $color-primary;
        font-weight: 700;
        margin-left: 4px;
        font-family: $font-roboto;
    }
}

&[dir="rtl"] {
    .select2-selection--multiple {
        .select2-selection__choice,
        .select2-selection__placeholder,
        .select2-search--inline {
            float: right;
        }

        .select2-selection__choice {
            margin-left: 5px;
            margin-right: auto;
        }

        .select2-selection__choice__remove {
            margin-left: 2px;
            margin-right: auto;
        }
    }
}

&.select2-container--focus {
    .select2-selection--multiple {
        border: solid $color-primary 1px;
        outline: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

&.select2-container--disabled {
    .select2-selection--multiple {
        background-color: #eee;
        cursor: default;
    }

    .select2-selection__choice__remove {
        display: none;
    }
}
