@include b(avatar) {
    display: block;
    width: 60px;
    height: 60px;
    border: 1px solid $color-gray;
    border-radius: 2px;
    background-color: $color-white;
    background-size: 85%;
    @include m(user) {
        background-color: $color-white;
        background-image: url('%=static=%assets/avatar/user.svg');
        background-repeat: no-repeat;
        background-position: 50% 50%;
    }
    @include m(large) {
        width: 300px;
        height: 325px;
    }
    @include m(big) {
        width: 130px;
        height: 130px;
    }
    @include m(small) {
        width: 30px;
        height: 30px;
    }
    @include m(profile) {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color-gray;
        background-image: url('%=static=%assets/avatar/user2.svg');
        background-size: 50%;
    }
    @include m(cards) {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1px solid $color-gray;
        border-radius: 0;
        color: $color-white;
        background-color: $color-gray;
        font-size: 50px;
        align-items: center;
        justify-content: center;
    }
}
