@include b(page) {
    @include e(wrapper) {
        display: flex;
        overflow-x: hidden;
        flex-direction: column;
        min-height: 100vh;
    }
    @include e(main) {
        padding-bottom: 40px;
        flex: 1 0 auto;
    }
    @include e(header footer) {
        flex-shrink: 0;
    }
}
