@include b(description) {
    @include e(row) {
        &:not(:last-child) {
            margin-bottom: 12px;
        }
    }
    @include e(text) {
        margin-bottom: 10px;
        color: $color-main;
        font-size: 14px;
        font-weight: 500;
    }
}
