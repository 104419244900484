.select2-container--light {
    @import "single";
    @import "multiple";

    .select2-dropdown {
        border: 1px solid $color-primary;
        border-radius: 2px;
        background-color: $color-white;
    }
    &.select2-container--open .select2-dropdown--below {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top: 0;
    }
    .select2-search--dropdown {
        .select2-search__field {
            border: 1px solid #aaa;
        }
    }

    .select2-search--inline {
        .select2-search__field {
            background: transparent;
            border: none;
            outline: 0;
            box-shadow: none;
            appearance: textfield;
            margin-top: 7px;
            font-size: 13px;
            line-height: 19px;
            vertical-align: top;
        }
    }

    .select2-results > .select2-results__options {
        max-height: 200px;
        overflow-y: auto;
    }

    .select2-results__option {
        font-size: 12px;
        padding: 10px 15px;
        line-height: 1;
        color: $placeholder-color;
        &[role=group] {
            padding: 0;
        }

        &[aria-disabled=true] {
            color: #999;
        }

        &[aria-selected=true] {
            background-color: $color-info;
            color: $color-white;
            .icon {
                color: $color-white;
            }
        }

        .select2-results__option {
            padding-left: 1em;

            .select2-results__group {
                padding-left: 0;
            }

            .select2-results__option {
                margin-left: -1em;
                padding-left: 2em;

                .select2-results__option {
                    margin-left: -2em;
                    padding-left: 3em;

                    .select2-results__option {
                        margin-left: -3em;
                        padding-left: 4em;

                        .select2-results__option {
                            margin-left: -4em;
                            padding-left: 5em;

                            .select2-results__option {
                                margin-left: -5em;
                                padding-left: 6em;
                            }
                        }
                    }
                }
            }
        }
    }

    .select2-results__option--highlighted[aria-selected] {
        background-color: $color-info;
        color: $color-white;
        .icon {
            color: $color-white;
        }
    }

    .select2-results__group {
        cursor: default;
        display: block;
        padding: 6px;
    }
}
