// fonts
// $icons: (
//     name: "\e601",
// );
// @include b(icon) {
//     font-family: 'Ico Moon';
//     speak: none;
//     font-style: normal;
//     font-weight: normal;
//     font-variant: normal;
//     text-transform: none;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//     display: inline-block;
//     vertical-align: middle;
//     line-height: 1;
//     @include m('name') {
//         @include icon(before, name);
//         margin-right: 10px;
//     }
// }
// raster
// @include b(ico) {
//     display: inline-block;
//     vertical-align: middle;
//     speak: none;
//     @include m('name') {
//         @include bg($ico-name);
//     }
// }
// svg
@include b('icon') {
    display: block;
    width: 1em;
    height: 1em;
    fill: currentColor;
    @include m(select) {
        display: inline-block;
        margin-right: 10px;
        color: $color-info;
        font-size: 17px;
        vertical-align: middle;
    }
    @include m(eye) {
        height: 0.5em;
    }
    @include m(message friends) {
        width: 1.33em;
    }
}
