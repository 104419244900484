@include b(tools) {
    @include e(list) {
        display: flex;
        margin: -20px -10px 0;
        align-items: center;
        flex-wrap: wrap;
    }
    @include e(item) {
        display: flex;
        padding: 20px 10px 0;
        align-items: center;

        .icon--eye {
            font-size: 39px;
            @include media('<tablet-small') {
                font-size: 22px;
            }
        }
    }
    @include e(icon) {
        margin-right: 6px;
        color: $color-info;
        font-size: 25px;
        @include media('<tablet-small') {
            font-size: 18px;
        }
    }
    @include e(action) {
        display: flex;
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        text-transform: uppercase;
        align-items: center;
        @include media('<tablet-small') {
            font-size: 12px;
            flex-wrap: wrap;
        }
    }
    @include e(link) {
        margin-left: 5px;
        color: $color-primary;
        @include on-event() {
            text-decoration: underline;
        }
    }
    @include e(label) {
        color: $color-info;
    }
    @include e(value) {
        margin-left: 5px;
        color: $color-main;
    }
}
