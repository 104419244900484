@include b(heading) {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    @include media('<tablet') {
        flex-direction: column;
    }
    @include m(center) {
        justify-content: center;
    }
    @include m(left) {
        justify-content: flex-start;

        .heading__item {
            @include media('>=tablet') {
                &:not(:first-child) {
                    margin-left: 20px;
                }
            }
        }
    }
    @include e(item) {
        display: flex;
        align-items: center;

        &:not(:first-child) {
            flex-wrap: wrap;
        }
        @include media('<tablet') {
            &:not(:first-child) {
                margin-top: 10px;
            }
        }
        @include media('<tablet-small') {
            flex-wrap: wrap;
        }
        @include m(report) {
            @include media('<tablet-small') {
                display: none;
            }
        }
    }
    @include e(icon) {
        display: inline-block;
        margin-right: 5px;
        color: $color-light;
        font-size: 30px;
        line-height: 1;
        vertical-align: middle;
        @include media('<tablet-small') {
            display: none;
        }
    }
    @include e(title) {
        color: $color-main;
        font-family: $font-raleway;
        font-size: 18px;
        font-weight: 900;
        text-transform: uppercase;
        @include media('<tablet-small') {
            font-size: 16px;
            text-align: center;
        }

        span {
            color: $color-primary;
            font-weight: 400;
            text-transform: none;
        }

        strong {
            color: $color-primary;
            font-weight: 900;
        }

        em {
            color: $color-info;
            font-family: $font-roboto;
            font-weight: 500;
            font-style: normal;
        }
    }
    @include e(link) {
        @extend %underline;
        margin-right: 30px;
        padding: 0 0 5px;
        color: $color-info;
        font-family: $font-raleway;
        font-size: 18px;
        font-weight: 900;
        text-transform: uppercase;

        &::before {
            top: calc(100% - 3px);
            height: 2px;
            background-color: $color-primary;
            transition: none;
        }
        @include is(active) {
            color: $color-primary;

            &::before {
                transform: scale(1);
            }
        }
        @include media('<tablet') {
            margin-top: 10px;
        }
    }
    @include e(drop) {
        margin-left: 10px;
    }
}
