@include b(watch) {
    @include e(banner) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    @include e(content) {
        border: 1px solid $color-gray;
        border-bottom-right-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
        background-color: $color-white;
        box-shadow: $box-shadow;
    }
    @include e(row) {
        padding: 20px;
        @include media('<tablet-small') {
            padding: 10px;
        }

        &:not(:first-child) {
            border-top: 1px solid $color-gray;
        }
    }
    @include e(action) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        @include media('<tablet-small') {
            flex-direction: column;
        }

        .tools {
            @include media('<tablet-small') {
                flex-direction: row;
                margin-bottom: 10px;
                flex-wrap: nowrap;
            }
        }
    }
}
