.collapse {
    display: none;
}
.collapse2 {
    display: block;
    @include media('<tablet-small') {
        display: none;
    }
}
.collapse2.collapsing {
    display: block;
}
.collapse.in {
    display: block;
}

tr.collapse.in {
    display: table-row;
}

tbody.collapse.in {
    display: table-row-group;
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition-timing-function: ease;
    transition-duration: .35s;
    transition-property: height;
}
.collapse-container {
    @include media('<tablet-small') {
        margin-top: 10px;
    }
}
