// -----------------------------------------------------------------------------
// This file contains very layout styles.
// -----------------------------------------------------------------------------

@include b(g-block) {
    margin-top: 30px;
    @include media('<tablet-small') {
        margin-top: 15px;
    }
}

@include b(g-row) {
    display: flex;
    @include media('<tablet') {
        flex-direction: column;
    }
}

@include b(g-row-model) {
    display: flex;
    @include media('<desktop') {
        flex-direction: column;
    }
}

@include b(g-footer) {
    &-row {
        display: flex;
        flex-wrap: wrap;
        @include media('<desktop') {
            flex-direction: column;
        }
    }

    &-col {
        @include media('>=desktop') {
            min-width: 1px;
            flex: 1;
        }
    }
}

@include b(g-row-community) {
    display: flex;
    @include media('<1200px') {
        flex-direction: column;
    }

    .cards {
        &:not(:first-child) {
            margin-top: 30px;
        }
    }
}

@include b(g-col-event) {
    @include media('>=1200px') {
        flex: 1 1 40%;
    }

    .media__list {
        padding: 20px;
        border: 1px solid $color-gray;
        background-color: $color-white;
        box-shadow: $box-shadow;
        @include media('<tablet-small') {
            padding: 10px;
        }
    }
}

@include b(g-col-other) {
    margin-top: 20px;
    @include media('>=1200px') {
        margin-top: 0;
        margin-left: 20px;
        flex: 1 1 60%;
    }
}

@include b(g-col-wide) {
    min-width: 1px;
    flex: 1 1 auto;
}

@include b(g-col-sidebar) {
    margin-left: 20px;
    padding: 30px;
    border: 1px solid $color-gray;
    border-radius: $border-radius;
    background-color: $color-white;
    box-shadow: $box-shadow;
    flex-shrink: 0;
    @include media('<1100px') {
        margin-top: 20px;
        margin-left: 0;
    }
    @include media('<tablet-small') {
        display: none;
    }
}

@include b(g-col-model) {
    margin-left: 20px;
    flex-shrink: 0;
    flex-basis: 300px;
    @include media('<desktop') {
        margin-bottom: 20px;
        margin-left: 0;
        flex-basis: auto;
        flex-shrink: 1;
        order: -1;
    }
}

@include b(g-row-banner) {
    display: flex;
    @include media('<1100px') {
        flex-direction: column;
    }
}

@include b(g-row-profile) {
    @include media('>=1000px') {
        display: flex;
    }
}

@include b(g-col-profile) {
    @include media('>=1000px') {
        position: relative;
        z-index: 2;
        top: -350px;
        max-width: 300px;
        margin-right: 40px;
    }
}
