@include b(profile) {
    @include e(bg) {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 310px;
        background-image: url('https://api.fnkr.net/testimg/1920x310/adbbbf/ffffff/?text=Profile');
        background-repeat: no-repeat;
        background-position: center;
        @include media('<1000px') {
            height: 0;
            padding-bottom: 20%;
        }
    }
    @include e(menu) {
        border: 1px solid $color-gray;
        background-color: $color-white;
        box-shadow: $box-shadow;

        nav {
            ul {
                display: flex;
                margin: 0 -20px;
                flex-wrap: wrap;
                @include media('>=1000px') {
                    margin-left: 320px;
                }
                @include media('<1000px') {
                    margin: 0 -10px;
                    justify-content: center;
                }

                li {
                    padding: 0 20px;
                    @include media('<1000px') {
                        padding: 0 10px;
                    }
                }

                a {
                    @extend %underline;
                    display: block;
                    padding: 15px 0;
                    color: $color-info;
                    font-family: $font-roboto;
                    font-size: 14px;
                    font-weight: 700;
                    text-align: center;
                    text-transform: uppercase;
                    @include media('<1000px') {
                        padding: 10px 0;
                    }

                    &::before {
                        height: 2px;
                        margin: -1px 0 0;
                        background-color: $color-primary;
                        transition: none;
                    }
                    @include on-event() {
                        color: $color-primary;

                        &::before {
                            transform: scale(1);
                        }

                        span {
                            color: $color-primary;
                        }
                    }
                    @include is(active) {
                        color: $color-primary;
                        pointer-events: none;
                        &::before {
                            transform: scale(1);
                        }

                        span {
                            color: $color-primary;
                        }
                    }

                    span {
                        display: block;
                        color: $color-main;
                    }

                    strong {
                        display: block;
                    }
                }
            }
        }
    }
    @include e(row) {
        @extend .model__row;

        &:last-child {
            padding-bottom: 15px;
        }
        @include media('<1000px', '>=tablet-small') {
            width: 50%;
            border: 0 !important;
        }
    }
    @include e(info) {
        padding: 0 10px;
        border: 1px solid $color-gray;
        background-color: $color-white;
        box-shadow: $box-shadow;
        @include media('>=1000px') {
            height: calc(100% - 65px);
        }
        @include media('<1000px', '>=tablet-small') {
            display: flex;
        }
    }
    @include e(tools) {
        display: flex;
        position: absolute;
        right: 10px;
        bottom: 10px;
        left: 10px;
        border: 1px solid $color-gray;
        border-radius: $border-radius;
        background-color: $color-white;
        box-shadow: $box-shadow;
    }
    @include e(avatar) {
        overflow: hidden;
        position: relative;
        width: 300px;
        margin-bottom: 50px;
        border-radius: 1px;
        box-shadow: 0 0 9px rgba(35, 31, 32, 0.33);
        @include media('<1000px') {
            margin-top: 15px;
            margin-right: auto;
            margin-bottom: 15px;
            margin-left: auto;
        }

        img {
            display: block;
            width: 100%;
        }
    }
    @include e(tools-btn) {
        padding: 8px;
        color: $color-neutral;
        font-family: $font-raleway;
        font-size: 15px;

        &:nth-child(2) {
            border-right: 1px solid $color-gray;
            border-left: 1px solid $color-gray;
            color: $color-light;
            font-size: 11px;
            font-weight: 700;
            text-transform: uppercase;
            flex-grow: 1;
        }

        strong {
            color: $color-primary;
        }
        @include on-event() {
            color: $color-primary;
        }
    }
}
