@include b(form) {
    @include e(group) {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
        @include m(inline) {
            @include media('>=tablet-small') {
                display: flex;

                .form__hold {
                    flex: 1 1 auto;
                }

                .label {
                    margin: 15px 10px 0 0;
                    flex-basis: 140px;
                }
            }
        }
    }
    @include m(inline) {
        display: flex;
        margin: -10px -10px 0;
        flex-wrap: wrap;

        .form__group .label {
            margin: 0 10px 0 0;
            flex-basis: auto;
            @include media('<tablet-small') {
                margin: 0 10px 10px 0;
            }
        }

        .form__group {
            margin: 0;
            padding: 10px 10px 0;
            align-items: center;
        }

        .checkbox-list {
            display: flex;
            align-items: center;
        }

        .checkbox-list__item {
            &:not(:first-child) {
                margin-top: 0;
                margin-left: 15px;
            }
        }
    }
    @include m(search) {
        .form__group:first-child {
            flex-grow: 1;
        }
        @include media('<tablet-small') {
            flex-direction: column;
        }
    }
    @include e(text) {
        color: $color-info;
        font-family: $font-raleway;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
    }
    @include e(link) {
        color: $color-primary;
        @include on-event() {
            text-decoration: underline;
        }
    }
    @include e(hold) {
        @include m(inline) {
            display: flex;
            align-items: center;
        }
    }
    @include e(separator) {
        padding: 0 7px;
        color: $color-light;
        line-height: 1;
    }
    @include e(buttons) {
        font-size: 0;

        .btn {
            &:not(:first-child) {
                margin-left: 30px;
            }
        }

        .btn--link {
            color: $color-primary;
            font-family: $font-raleway;
            font-weight: 700;
            text-transform: uppercase;

            .btn__text {
                font-size: 12px;
            }
            @include on-event() {
                .btn__text {
                    text-decoration: underline;
                }
            }
        }
        @include m(center) {
            text-align: center;
        }
        @include m(right) {
            text-align: right;
        }
    }
    @include e(columns) {
        @include media('>=tablet') {
            display: flex;
            margin: 0 -20px;
        }
    }
    @include e(column) {
        @include media('>=tablet') {
            width: 50%;
            padding: 0 20px;
        }
        @include media('<tablet') {
            margin-top: 20px;
        }
    }
    @include e(avatar) {
        display: flex;
        margin-top: 10px;
        align-items: flex-start;

        &-check {
            display: flex;
            margin-left: 10px;
            align-items: center;
        }

        &-label {
            margin-left: 8px;
            color: $color-info;
            font-size: 13px;
            font-weight: 400;
            cursor: pointer;
        }
    }
    @include e(birth) {
        display: flex;

        .select2 {
            flex: 1 1 auto;
        }

        select:not(:first-child) + .select2 {
            margin-left: 3px;
            @include media('<tablet-small') {
                margin-top: 10px;
                margin-left: 0;
            }
        }
        @include media('<tablet-small') {
            flex-wrap: wrap;
        }
    }
    @include e(hint) {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
    }
}

select {
    height: 45px;
}

@include b(checkbox) {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    vertical-align: top;
    @include e(field) {
        display: none;

        &:checked + .checkbox__label::after {
            border-color: $color-main;
        }
    }
    @include e(label) {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1px solid $color-gray;
        border-radius: 2px;
        background-color: $color-white;

        &::after {
            display: block;
            position: absolute;
            top: 9px;
            left: 3px;
            width: 6px;
            height: 12px;
            border-top: 1px solid transparent;
            border-right: 1px solid transparent;
            content: '';
            transform: scaleX(-1) rotate(135deg);
            transform-origin: left top;
        }
    }
}

@include b(radio) {
    display: inline-block;
    position: relative;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    vertical-align: top;
    @include e(field) {
        display: none;

        &:checked + .radio__label {
            border-color: $color-main;
        }

        &:checked + .radio__label::after {
            transform: scale(1);
        }
    }
    @include e(label) {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 2px solid $color-light;
        border-radius: 50%;
        background-color: $color-white;

        &::after {
            display: block;
            position: absolute;
            top: 3px;
            left: 3px;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: $color-primary;
            content: '';
            transform: scale(0);
        }
    }
    &-holder {
        display: flex;
        margin-bottom: 8px;
        align-items: center;

        .label {
            margin: 0 0 0 10px;
        }
    }
}

@include b(file-control) {
    position: relative;
    width: 100%;
    & + & {
        margin-top: 10px;
    }

    .button {
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 5px 6px;
        border-radius: 3px;
        color: $color-white;
        background-color: $color-info;
        font-size: 12px;
        font-weight: 500;
        line-height: 1;
        cursor: pointer;
    }

    .file {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        opacity: 0;
        cursor: pointer;
    }
}

@include b(checkbox-list) {
    @include e(label) {
        margin-top: 1px;
        margin-left: 10px;
        color: $color-main;
        font-size: 12px;
        font-weight: 500;
        line-height: 1;
        text-transform: uppercase;
    }
    @include e(item) {
        display: flex;
        align-items: center;

        &:not(:first-child) {
            margin-top: 15px;
        }
    }
}
.message-form {
    margin-top: 15px;
}
