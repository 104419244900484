@include b(model) {
    padding: 10px;
    border: 1px solid $color-gray;
    border-radius: $border-radius;
    background-color: $color-white;
    box-shadow: $box-shadow;
    @include e(avatar) {
        overflow: hidden;
        position: relative;
        max-width: 300px;
        margin: auto;
        @include aspect-ratio(300, 326, 'img');
        @include media('<tablet-small') {
            max-width: 100%;
        }
    }
    @include e(rank) {
        position: absolute;
        z-index: 2;
        bottom: 10px;
        left: 0;
        padding: 6px 10px;
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        color: $color-white;
        background-color: rgba($color-primary, 0.8);
        font-size: 14px;
        line-height: 1;

        span {
            font-size: 18px;
            font-weight: 700;
        }
    }
    @include e(flag) {
        display: flex;
        position: absolute;
        z-index: 2;
        right: 0;
        bottom: 10px;
        padding: 6px 10px;
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
        color: $color-white;
        background-color: rgba($color-black, 0.8);
        font-size: 18px;
        line-height: 1;
        align-items: center;

        .icon {
            margin-right: 6px;
        }
    }
    @include e(row) {
        padding: 15px 0;

        &:not(:first-child) {
            border-top: 1px solid $color-gray;
        }

        @include m(banner) {
            padding-bottom: 0;
            @include media('<tablet-small') {
                display: none;
            }
        }
    }
    @include e(subscribe-count) {
        display: block;
        color: $color-main;
        font-weight: 500;
        line-height: 1;
    }
    @include e(alias) {
        margin-bottom: 15px;
        color: $color-primary;
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        text-transform: uppercase;
    }
    @include e(share) {
        display: block;
        margin-bottom: 15px;
        color: $color-info;
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        text-transform: uppercase;
    }
}
