@include b(badge) {
    display: flex;
    align-items: center;
    @include media('<tablet-small') {
        flex-direction: column;
        align-items: center;
    }
    @include e(list) {
        display: flex;
        margin: -5px 0 0 -3px;
        flex-wrap: wrap;
        @include media('<tablet-small') {
            justify-content: center;
        }
    }
    @include e(item) {
        display: flex;
        margin: 5px 0 0 3px;
        align-items: center;
    }
    @include e(label) {
        margin: 0 10px 0 0;
        color: $color-info;
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        text-transform: uppercase;
        @include media('<tablet-small') {
            margin: 0 0 10px;
        }
    }
    @include e(link) {
        @extend .btn;
        padding: 6px 10px;
        border: 1px solid $color-gray;
        border-radius: $border-radius;
        color: $color-light;
        background-color: $color-white;
        box-shadow: $box-shadow;
        font-size: 11px;
        font-weight: 700;
        text-transform: uppercase;
        @include on-event() {
            border-color: $color-primary;
            color: $color-white;
            background-color: $color-primary;
        }
        @include m(primary) {
            background-color: $color-gray;
            @include on-event() {
                border-color: $color-info;
                background-color: $color-info;
            }
        }
    }
}
