@include b(subscribe) {
    display: inline-flex;
    align-items: center;
    @include e(item) {
        &:not(:first-child) {
            margin-left: 25px;
        }
    }
    @include e(count) {
        color: $color-main;
        font-weight: 500;
    }
}
