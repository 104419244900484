@include b(banners) {
    padding: 28px;
    border: 1px solid $color-gray;
    border-radius: $border-radius;
    background-color: $color-white;
    box-shadow: $box-shadow;
    @include media('<tablet') {
        padding: 0;
        border: 0;
        background-color: transparent;
        box-shadow: none;
    }
    @include m(sidebar model) {
        padding: 0;
        border: 0;
        background-color: transparent;
        box-shadow: none;

        &.is_stuck {
            @include media('>=1100px') {
                padding: 30px 0 0;
            }
        }

        .banners__list {
            flex-direction: row;
            justify-content: center;
            @include media('>=1100px') {
                flex-direction: column;
                margin: -30px 0 0;
                justify-content: flex-start;
            }
        }

        .banners__item {
            @include media('>=1100px') {
                margin: 30px 0 0;
                padding: 0;
            }
        }
    }
    @include m(model) {
        .banners__list {
            @include media('>=1100px') {
                margin: -10px 0 0;
            }
        }

        .banners__item {
            @include media('>=1100px') {
                margin: 10px 0 0;
                padding: 0;
            }
        }
    }
    @include e(list) {
        display: flex;
        margin: -15px -15px 0;
        flex-wrap: wrap;
        justify-content: center;
        @include media('<tablet') {
            margin: -10px -5px 0;
        }
    }
    @include e(item) {
        padding: 15px 15px 0;
        @include media('<tablet') {
            padding: 10px 5px 0;
        }
    }
}
