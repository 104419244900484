@include b(field) {
    display: block;
    width: 100%;
    height: 42px;
    padding: 0 15px;
    border: 1px solid $color-gray;
    border-radius: 2px;
    color: $placeholder-color;
    background-color: $color-white;
    font-family: $font-roboto;
    font-size: 13px;
    font-weight: 400;
    transition: border-color 0.3s, box-shadow 0.3s, color 0.3s;
    @include is(error) {
        border-color: $color-primary;
        color: $color-primary;
    }

    &:focus {
        border-color: $color-primary;
        outline: 0;
    }
    @include m(area) {
        height: auto;
        min-height: 125px;
        padding: 15px;
        resize: vertical;

        .swiper-wrapper & {
            min-height: 90px;
            resize: none;
        }
    }
}
