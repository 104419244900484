@include b(channel) {
    @include e(avatar) {
        position: relative;
        @include aspect-ratio(1680, 309, 'img');
    }
    @include e(info) {
        border: 1px solid $color-gray;
        border-top: 0;
        background-color: $color-white;
        box-shadow: $box-shadow;
    }
    @include e(list) {
        display: flex;
        padding: 10px 20px;
        justify-content: space-between;
        flex-wrap: wrap;
        @include e(item) {
            display: flex;
            align-items: center;
            @include media('<1300px') {
                &:nth-child(2) {
                    width: 100%;
                    margin-top: 15px;
                    order: 3;
                }
            }
            @include media('<tablet') {
                &:not(:first-child) {
                    margin-top: 15px;
                }
            }
            @include media('<tablet-small') {
                flex-wrap: wrap;
                justify-content: center;

                &:first-child {
                    .channel__sub:first-child {
                        width: 100%;
                        margin-bottom: 10px;
                        text-align: center;
                    }

                    .channel__sub:nth-child(2) {
                        margin-left: 0;
                    }
                }
            }
        }
        @include media('<1300px') {
            padding: 10px;
        }
        @include media('<tablet') {
            flex-direction: column;
            align-items: center;
        }
    }
    @include e(title) {
        color: $color-primary;
        font-family: $font-raleway;
        font-size: 18px;
        font-weight: 900;
        text-transform: uppercase;
        @include media('<tablet-small') {
            font-size: 16px;
        }
    }
    @include e(sub) {
        &:not(:first-child) {
            margin-left: 25px;
        }
    }
    @include e(rank) {
        display: block;
        color: $color-info;
        font-size: 24px;
        font-weight: 500;
        text-transform: uppercase;
        @include media('<tablet-small') {
            font-size: 18px;
        }
        span {
            color: $color-primary;
        }
    }
    @include e(label count) {
        color: $color-info;
        font-weight: 500;
        text-transform: uppercase;
    }
    @include e(count) {
        display: block;
        color: $color-main;
    }
    @include e(join) {
        padding: 11px 115px;
        border-radius: $border-radius;
        color: $color-white;
        background-color: $color-primary;
        font-family: $font-raleway;
        font-size: 24px;
        font-weight: 700;
        white-space: nowrap;
        text-transform: uppercase;
        @include media('<tablet-small') {
            font-size: 18px;
        }
        @include on-event() {
            color: $color-white;
            background-color: darken($color-primary, 5%);
        }
        @include media('<1300px') {
            display: block;
            width: 100%;
            padding: 11px;
            text-align: center;
        }
    }
    @include e(description) {
        padding: 15px 20px;
        border-top: 1px solid $color-gray;
        font-weight: 500;
        line-height: 1.6;
        @include media('<tablet-small') {
            padding: 10px;
        }
    }
}
