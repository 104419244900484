@include b(language) {
    font-size: 0;
    @include e(button) {
        display: inline-block;
        position: relative;
        z-index: 1;
        padding: 11px;
        border-radius: $border-radius;
        outline: 0;
        color: $color-white;
        font-family: $font-raleway;
        font-size: 0;
        font-weight: 700;
        text-transform: uppercase;
        @include on-event() {
            outline: 0;
            color: $color-primary;
            background-color: $bg-dark;
        }

        &[aria-expanded='true'] {
            outline: 0;
            color: $color-primary;
            background-color: $bg-dark;

            .language__arrow {
                transform: rotate(-180deg);
            }
        }
        @include on-event() {
            outline: 0;
            color: $color-primary;
            background-color: $bg-dark;

            .language__arrow {
                transform: rotate(-180deg);
            }
        }
    }
    @include e(icon text arrow) {
        display: inline-block;
        vertical-align: middle;
    }
    @include e(icon) {
        margin-right: 5px;
        font-size: 16px;
    }
    @include e(text) {
        font-size: 12px;
    }
    @include e(arrow) {
        margin-left: 5px;
        font-size: 11px;
    }
}
