@include b(btn-group) {
    display: flex;
    margin: -5px 0 0 -5px;
    flex-wrap: wrap;
    @include e(item) {
        display: flex;
        margin: 5px 0 0 5px;
        align-items: center;
    }
}

@include b(btn) {
    display: inline-block;
    overflow: hidden;
    position: relative;
    z-index: 1;
    padding: 10px 20px;
    border: 1px solid transparent;
    border-radius: $border-radius;
    outline: 0;
    color: inherit;
    background-color: transparent;
    font-family: $font-raleway;
    font-size: 0;
    font-weight: 400;
    line-height: 1;
    vertical-align: middle;
    white-space: nowrap;
    text-transform: uppercase;
    cursor: pointer;
    user-select: none;

    &:disabled,
    &.is-disabled {
        opacity: 0.6;
        cursor: not-allowed;
        pointer-events: none;
    }
    @include m(dark) {
        padding: 12px;
        border-color: transparent;
        color: $color-white;
        background-color: $bg-dark;
        font-weight: 700;
        text-transform: uppercase;
        @include on-event() {
            color: $color-white;
            background-color: $color-primary;
        }
        @include is(active) {
            color: $color-white;
            background-color: $color-primary;
        }

        .open & {
            color: $color-white;
            background-color: $color-primary;
        }
        @include on-event() {
            color: $color-white;
            background-color: $color-primary;
        }
    }
    @include m(primary) {
        padding: 12px 19px;
        color: $color-white;
        background-color: $color-primary;
        font-weight: 700;
        text-transform: uppercase;

        .btn__text {
            font-size: 12px;
        }
        @include on-event() {
            color: $color-white;
            background-color: darken($color-primary, 5%);
        }
        @include is(active) {
            color: $color-white;
            background-color: darken($color-primary, 5%);
        }
    }
    @include m(secondary) {
        padding: 13px 20px;
        color: $color-white;
        background-color: $color-info;
        font-weight: 700;
        text-transform: uppercase;

        .btn__text {
            font-size: 12px;
        }
        @include on-event() {
            background-color: darken($color-info, 10%);
        }
    }
    @include m(icon) {
        padding: 5px 10px;

        .btn__icon {
            font-size: 28px;
        }

        .btn__arrow {
            font-size: 11px;
        }
    }
    @include m(icon-light) {
        padding: 0;
        color: $color-info;

        .btn__icon {
            font-size: 28px;
        }

        .btn__arrow {
            font-size: 11px;
        }
        @include on-event() {
            color: $color-primary;
        }

        .open & {
            color: $color-primary;
        }
    }
    @include m(link) {
        padding: 0;
        color: $color-info;

        .btn__icon {
            font-size: 24px;
        }

        .btn__text {
            font-size: 16px;
            font-weight: 700;
            text-transform: uppercase;
        }
        @include on-event() {
            color: $color-primary;
        }
        @include is(active) {
            color: $color-primary;
        }
    }
    @include m(more) {
        border: 1px solid $color-gray;
        border-radius: $border-radius;
        color: $color-light;
        background-color: $color-white;
        box-shadow: $box-shadow;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        @include on-event() {
            border-color: $color-primary;
            color: $color-white;
            background-color: $color-primary;
        }
    }
    @include m(border) {
        padding: 10px 30px;
        border-color: $color-gray;
        color: $color-light;
        background-color: $color-white;
        box-shadow: $box-shadow;
        font-weight: 700;

        .open & {
            color: $color-primary;
        }
        @include on-event() {
            color: $color-primary;
        }
    }
    @include m(block) {
        width: 100%;
    }
    @include m(medium) {
        padding: 10px 20px;
    }
    @include m(collapse) {
        display: none;
        @include media('<tablet-small') {
            display: block;
        }

        &::before {
            display: inline-block;
            font-size: 12px;
            vertical-align: middle;
            content: attr(data-text);
        }

        &[aria-expanded='true']::before {
            content: attr(data-text-expand);
        }
    }
    @include m(danger) {
        padding: 12px 19px;
        color: $color-white;
        background-color: $color-primary;
        font-weight: 700;
        text-transform: uppercase;
        padding: 10px 20px;

        @include on-event() {
            color: $color-white;
            background-color: darken($color-primary, 5%);
        }
        @include is(active) {
            color: $color-white;
            background-color: darken($color-primary, 5%);
        }
    }
    @include e(icon) {
        display: inline-block;
        font-size: 15px;
        vertical-align: middle;

        & + .btn__text {
            margin-left: 5px;
        }
    }
    @include e(arrow) {
        display: inline-block;
        margin-left: 5px;
        font-size: 7px;
        vertical-align: middle;

        .open & {
            transform: rotate(-180deg);
        }
    }
    @include e(text) {
        display: inline-block;
        font-size: 11px;
        vertical-align: middle;
    }
}
