@include b(list-column) {
    margin: -5px 0 -12px;
    column-count: 6;
    column-gap: 40px;
    @include media('<desktop-large') {
        column-count: 5;
    }
    @include media('<desktop') {
        column-count: 4;
        column-gap: 20px;
    }
    @include media('<tablet') {
        column-count: 3;
        column-gap: 10px;
    }
    @include media('<tablet-small') {
        column-count: 2;
    }
    @include e(item) {
        padding: 12px 0;
        break-inside: avoid-column;
    }
    @include e(link) {
        display: inline-flex;
        line-height: 1;
        align-items: center;
        justify-content: flex-start;
        @include on-event() {
            .list-column__title {
                color: $color-primary;
            }

            .list-column__count {
                color: $color-primary;
            }
        }
    }
    @include e(letter) {
        display: block;
        width: 100%;
        margin-bottom: 20px;
        color: $color-primary;
        font-family: $font-raleway;
        font-size: 18px;
        font-weight: 900;
        text-transform: uppercase;
    }
    @include e(title) {
        display: block;
        overflow: hidden;
        color: $color-main;
        font-size: 14px;
        font-weight: 500;
        white-space: nowrap;
        text-transform: uppercase;
        text-overflow: ellipsis;
        @include media('<tablet-small') {
            font-size: 12px;
        }
    }
    @include e(count) {
        margin-left: 5px;
        color: $color-neutral;
        font-size: 14px;
        font-weight: 500;
        @include media('<tablet-small') {
            font-size: 12px;
        }
    }
}
