@include b(pagination) {
    padding-top: 30px;
    @include media('<tablet-small') {
        padding-top: 15px;
    }
    @include e(list) {
        display: flex;
        margin: -4px -2px 0;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        @include media('<tablet-small') {
            flex-wrap: nowrap;
        }
    }
    @include e(item) {
        margin: 4px 2px 0;
        @include media('<tablet-small') {
            display: none;
        }
        @include m(mobile) {
            display: block !important;
            @include media('<tablet-small') {
                flex: 1 1 33.333%;
            }
        }
    }
    @include e(icon) {
        font-size: 10px;
    }
    @include e(link) {
        display: inline-flex;
        min-width: 40px;
        height: 40px;
        padding: 5px 10px;
        border: 1px solid $color-gray;
        border-radius: 40px;
        color: $color-light;
        background-color: $color-white;
        box-shadow: $box-shadow;
        font-family: $font-roboto;
        font-size: 12px;
        font-weight: 700;
        line-height: 1;
        white-space: nowrap;
        text-decoration: none;
        text-transform: uppercase;
        cursor: pointer;
        user-select: none;
        align-items: center;
        justify-content: center;
        @include media('<tablet') {
            display: flex;
        }
        @include on-event() {
            color: $color-white;
            background-color: $color-primary;

            .pagination__icon {
                color: $color-white;
            }
        }
        @include is(active) {
            width: 65px;
            height: 65px;
            border: 0;
            color: $color-primary;
            background-image: url('%=static=%assets/pagination/brand-circle.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            box-shadow: none;
            cursor: auto;
            pointer-events: none;
            @include media('<tablet-small') {
                margin: auto;
            }
        }
        @include is(disabled) {
            opacity: 0.6;
            color: $color-black;
            background-color: $color-light;
            cursor: not-allowed;
            pointer-events: none;
        }
    }
}

@include b(more) {
    padding-top: 20px;
}
