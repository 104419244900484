// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/* reset browsers */
* {
    box-sizing: border-box;

    &::before,
    &::after {
        box-sizing: border-box;
    }
    @include selection {
        color: $selection-color;
        background: $selection-bg;
    }
}

form,
fieldset {
    margin: 0;
    padding: 0;
    border: 0;
}

img {
    max-width: 100%;
    height: auto;
    border: 0;
}

input,
select,
textarea,
button {
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
    appearance: none;
}

button {
    background: transparent;
    cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
p,
strong {
    margin: 0;
    padding: 0;
}

ul,
ol,
li {
    margin: 0;
    padding: 0;
    list-style: none;
}

a {
    color: $link-color;
    text-decoration: $link-decoration;

    &:hover {
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
    }
}

/* placeholder cross-browser */
input,
textarea {
    @include placeholder {
        color: $placeholder-color;
    }
}

/* general styles */
html {
    height: 100%;
}

body {
    min-width: 320px;
    height: 100%;
    color: $body-color;
    background-color: $body-bg;
    text-rendering: optimizeSpeed;
    -webkit-font-smoothing: antialiased;
}
