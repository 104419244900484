@include b(orientation) {
    @include e(list) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    @include e(button) {
        margin: 0 12px;
        color: $color-info;
        font-size: 19px;
        @include on-event() {
            outline: 0;
            color: $color-primary;
        }
        @include is(active) {
            color: $color-primary;
            pointer-events: none;
        }
    }
}
