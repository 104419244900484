.select2-selection--single {
    box-shadow: 0 1px 0 0 rgb(29, 38, 47) inset, 0 1px 3px 0 rgba(0, 0, 0, 0.8);
    background-image: linear-gradient(to top, #121a23 0%, #16202a 100%);
    border-radius: 4px;
    height: 45px;
    min-width: 115px;
    outline: 0;

    .select2-selection__rendered {
        color: #caced2;
        line-height: 45px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        padding-left: 15px;
        padding-right: 35px;
    }

    .select2-selection__clear {
        cursor: pointer;
        float: right;
        font-weight: bold;
    }

    .select2-selection__placeholder {
        color: #caced2;
    }

    .select2-selection__arrow {
        height: 45px;
        position: absolute;
        width: 20px;
        top: 0;
        right: 0;
        line-height: 45px;
        text-align: center;
        b {
            height: 11px;
            width: 10px;
            display: inline-block;
            position: relative;
            &::after, &::before {
                position: absolute;
                top: 0;
                left: 0;
                width: 7px;
                height: 7px;
                line-height: 7px;
                border-top: 1px solid $color-main;
                border-right: 1px solid $color-main;
                content: '';
                transform: rotate(-45deg);
                backface-visibility: hidden;
                transform-origin: center;
            }
            &::before {
                bottom: 0;
                top: auto;
                transform: rotate(135deg);
            }
        }
    }
}

&[dir="rtl"] {
    .select2-selection--single {
        .select2-selection__clear {
            float: left;
        }

        .select2-selection__arrow {
            left: 1px;
            right: auto;
        }
    }
}

&.select2-container--disabled {
    .select2-selection--single {
        background-color: #eee;
        cursor: default;

        .select2-selection__clear {
            display: none;
        }
    }
}

&.select2-container--open {
    .select2-dropdown {
        background-image: linear-gradient(to bottom, #16202a 0%, #121a23 100%);
    }
    .select2-selection--single {
        background-image: linear-gradient(to top, #16202a 0%, #121a23 100%);
    }
}
