@include b(share) {
    flex-direction: column;
    @include media('>=tablet') {
        display: flex;
        flex-direction: row;
    }
    @include e(form) {
        @include media('>=tablet') {
            flex: 1 1 60%;
        }
    }
    @include e(social) {
        margin-top: 20px;
        @include media('>=tablet') {
            flex: 1 1 40%;
            margin-left: 15px;
            margin-top: 0;
        }
    }
}
