@include b(report) {
    @include e(container) {
        display: flex;
        @include media('<tablet') {
            flex-direction: column;
        }
    }
    @include e(options) {
        @include media('<tablet') {
            margin-top: 20px;
            order: 2;
        }
    }
    @include e(hold) {
        @include media('>=tablet') {
            margin-left: 50px;
            flex: 1 1 auto;
        }
    }
    @include e(item) {
        display: flex;
        align-items: center;

        &:not(:first-child) {
            margin-top: 10px;
        }
    }
    @include e(action) {
        margin-right: 10px;
        line-height: 1;
    }
    @include e(text) {
        color: $color-main;
        font-size: 12px;
        font-weight: 500;
        line-height: 1;
        text-transform: uppercase;
        cursor: pointer;
    }
}
