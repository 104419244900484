@include b(slider) {
    position: relative;
    padding: 0 60px;

    .swiper-button-icon {
        width: 40px;
        height: 40px;
        font-size: 14px;
    }

    div[class*='swiper-button'] {
        padding: 0 10px;
    }
    @include e(container) {
        padding: 10px;
    }
    @include e(item) {
        width: 276px;
    }
}
