@include b(rating) {
    display: flex;
    align-items: center;
    @include e(action) {
        display: flex;
        margin-left: 15px;
    }
    @include e(progress) {
        display: block;
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 3px;
        border-radius: 1px;
        background: $color-primary;
        font-size: 0;
    }
    @include e(val) {
        position: absolute;
        top: 0;
        left: 0;
        height: 3px;
        border-radius: 1px;
        background: $color-secondary;
        transition: width $transition-speed;
    }
    @include e(btn) {
        font-family: $font-roboto;
        font-size: 0;
        line-height: 1;

        &-icon {
            display: inline-block;
            color: $color-info;
            font-size: 27px;
            vertical-align: middle;
        }

        &-text {
            display: inline-block;
            margin-left: 5px;
            font-size: 14px;
            font-weight: 500;
            vertical-align: middle;
            text-transform: uppercase;
        }
        @include m(like) {
            @include on-event() {
                .rating__btn-icon {
                    color: $color-secondary;
                }
            }
        }
        @include m(dislike) {
            margin-left: 15px;
            @include on-event() {
                .rating__btn-icon {
                    color: $color-primary;
                }
            }
        }
        @include is(disabled) {
            opacity: 0.6;
            cursor: not-allowed;
            pointer-events: none;
        }
    }
    @include e(text) {
        margin-bottom: 2px;
        line-height: 1;
        justify-content: space-between;
        @include is(success) {
            color: $color-success;
        }
        @include is(error) {
            color: $color-danger;
        }
    }
    @include e(count) {
        color: $color-info;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;

        strong {
            font-size: 12px;
            font-weight: 400;
        }
    }
}
