/* Magnific Popup CSS */

////////////////////////
//    Settings      //
////////////////////////
// overlay
$mfp-overlay-color:                   #171717 !default; // Color of overlay screen
$mfp-overlay-opacity:                 0.9 !default; // Opacity of overlay screen
$mfp-shadow:                          0 0 8px rgba(0, 0, 0, 0.6) !default; // Shadow on image or iframe
// spacing
$mfp-popup-padding-left:              20px !default; // Padding from left and from right side
$mfp-popup-padding-left-mobile:       10px !default; // Same as above, but is applied when width of window is less than 800px
$mfp-z-index-base:                    1040 !default; // Base z-index of popup
// controls
$mfp-include-arrows:                  true !default; // Include styles for nav arrows
$mfp-controls-opacity:                0.65 !default; // Opacity of controls
$mfp-controls-color:                  #fff !default; // Color of controls
$mfp-controls-border-color:           #3f3f3f !default; // Border color of controls
$mfp-inner-close-icon-color:          #333 !default; // Color of close button when inside
$mfp-controls-text-color:             #ccc !default; // Color of preloader and "1 of X" indicator
$mfp-controls-text-color-hover:       #fff !default; // Hover color of preloader and "1 of X" indicator
// Iframe-type options
$mfp-include-iframe-type:             true !default; // Enable Iframe-type popups
$mfp-iframe-padding:                  10px !default; // Iframe padding
$mfp-iframe-background:               #000 !default; // Background color of iframes
$mfp-iframe-max-width:                700px !default; // Maximum width of iframes
$mfp-iframe-ratio:                    9/16 !default; // Ratio of iframe (9/16 = widescreen, 3/4 = standard, etc.)
// Image-type options
$mfp-include-image-type:              true !default; // Enable Image-type popups
$mfp-image-background:                #444 !default;
$mfp-image-padding-top:               40px !default; // Image padding top
$mfp-image-padding-bottom:            40px !default; // Image padding bottom
$mfp-include-mobile-layout-for-image: true !default; // Removes paddings from top and bottom
// Image caption options
$mfp-caption-title-color:             #f3f3f3 !default; // Caption title color
$mfp-caption-subtitle-color:          #bdbdbd !default; // Caption subtitle color
// A11y
$mfp-use-visuallyhidden:              false !default; // Hide content from browsers, but make it available for screen readers
////////////////////////
//
// Contents:
//
// 1. General styles
//    - Transluscent overlay
//    - Containers, wrappers
//    - Cursors
//    - Helper classes
// 2. Appearance
//    - Preloader & text that displays error messages
//    - CSS reset for buttons
//    - Close icon
//    - "1 of X" counter
//    - Navigation (left/right) arrows
//    - Iframe content type styles
//    - Image content type styles
//    - Media query where size of arrows is reduced
//    - IE7 support
//
////////////////////////
////////////////////////
// 1. General styles
////////////////////////
// Transluscent overlay
.mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $mfp-z-index-base + 2;
    overflow: hidden;
    position: fixed;
    background: $mfp-overlay-color;
    opacity: $mfp-overlay-opacity;
}

// Wrapper for popup
.mfp-wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $mfp-z-index-base + 3;
    position: fixed;
    outline: none !important;
    backface-visibility: hidden; // fixes webkit bug that can cause "false" scrollbar
}

// Root container
.mfp-container {
    text-align: center;
    position: absolute;
    width: 100%;
    min-height: 100%;
    // height: 100%;
    left: 0;
    top: 0;
    padding: $mfp-popup-padding-left;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

// Popup content holder
.mfp-content {
    position: relative;
    margin: auto;
    text-align: left;
    width: 100%;
    z-index: $mfp-z-index-base + 5;
}

.mfp-inline-holder,
.mfp-ajax-holder {
    .mfp-content {
        width: 100%;
        cursor: auto;
    }
}

// Cursors
.mfp-ajax-cur {
    cursor: progress;
}

.mfp-vertical-top-cur {
    &,
    .mfp-image-holder .mfp-close {
        cursor: zoom-out;
    }
}

.mfp-zoom {
    cursor: pointer;
    cursor: zoom-in;
}

.mfp-auto-cursor {
    .mfp-content {
        cursor: auto;
    }
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
    user-select: none;
}

// Hide the image during the loading
.mfp-loading {
    &.mfp-figure {
        display: none;
    }
}

// Helper class that hides stuff
@if $mfp-use-visuallyhidden {
    // From HTML5 Boilerplate https://github.com/h5bp/html5-boilerplate/blob/v4.2.0/doc/css.md#visuallyhidden
    .mfp-hide {
        border: 0 !important;
        clip: rect(0 0 0 0) !important;
        height: 1px !important;
        margin: -1px !important;
        overflow: hidden !important;
        padding: 0 !important;
        position: absolute !important;
        width: 1px !important;
    }
}

@else {
    .mfp-hide {
        display: none !important;
    }
}

////////////////////////
// 2. Appearance
////////////////////////
// Preloader and text that displays error messages
.mfp-preloader {
    color: $mfp-controls-text-color;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -0.8em;
    left: 8px;
    right: 8px;
    z-index: $mfp-z-index-base + 4;
    a {
        color: $mfp-controls-text-color;
        &:hover {
            color: $mfp-controls-text-color-hover;
        }
    }
}

// Hide preloader when content successfully loaded
.mfp-s-ready {
    .mfp-preloader {
        display: none;
    }
}

// Hide content when it was not loaded
.mfp-s-error {
    .mfp-content {
        display: none;
    }
}

// CSS-reset for buttons
button {
    &.mfp-close,
    &.mfp-arrow {
        overflow: visible;
        cursor: pointer;
        background: transparent;
        border: 0;
        appearance: none;
        display: block;
        outline: none;
        padding: 0;
        z-index: $mfp-z-index-base + 6;
        box-shadow: none;
        touch-action: manipulation;
    }
    &::-moz-focus-inner {
        padding: 0;
        border: 0;
    }
}

// Close icon
.mfp-close {
    width: 44px;
    height: 44px;
    line-height: 44px;
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
    text-align: center;
    opacity: $mfp-controls-opacity;
    padding: 0 0 18px 10px;
    color: $mfp-controls-color;
    font-style: normal;
    font-size: 28px;
    font-family: Arial, Baskerville, monospace;
    &:hover,
    &:focus {
        opacity: 1;
    }
    &:active {
        top: 1px;
    }
}

.mfp-close-btn-in {
    .mfp-close {
        color: $mfp-inner-close-icon-color;
    }
}

.mfp-image-holder,
.mfp-iframe-holder {
    .mfp-close {
        color: $mfp-controls-color;
        right: -6px;
        text-align: right;
        padding-right: 6px;
        width: 100%;
    }
}

// "1 of X" counter
.mfp-counter {
    position: absolute;
    top: 0;
    right: 0;
    color: $mfp-controls-text-color;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;
}

// Navigation arrows
@if $mfp-include-arrows {
    .mfp-arrow {
        position: absolute;
        opacity: $mfp-controls-opacity;
        margin: 0;
        top: 50%;
        margin-top: -55px;
        padding: 0;
        width: 90px;
        height: 110px;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        &:active {
            margin-top: -54px;
        }
        &:hover,
        &:focus {
            opacity: 1;
        }
        &:before,
        &:after {
            content: '';
            display: block;
            width: 0;
            height: 0;
            position: absolute;
            left: 0;
            top: 0;
            margin-top: 35px;
            margin-left: 35px;
            border: medium inset transparent;
        }
        &:after {
            border-top-width: 13px;
            border-bottom-width: 13px;
            top: 8px;
        }
        &:before {
            border-top-width: 21px;
            border-bottom-width: 21px;
            opacity: 0.7;
        }
    }
    .mfp-arrow-left {
        left: 0;
        &:after {
            border-right: 17px solid $mfp-controls-color;
            margin-left: 31px;
        }
        &:before {
            margin-left: 25px;
            border-right: 27px solid $mfp-controls-border-color;
        }
    }
    .mfp-arrow-right {
        right: 0;
        &:after {
            border-left: 17px solid $mfp-controls-color;
            margin-left: 39px
        }
        &:before {
            border-left: 27px solid $mfp-controls-border-color;
        }
    }
}

// Iframe content type
@if $mfp-include-iframe-type {
    .mfp-iframe-holder {
        padding: $mfp-iframe-padding;
        .mfp-content {
            line-height: 0;
            width: 100%;
            max-width: $mfp-iframe-max-width;
        }
        .mfp-close {
            top: -40px;
        }
    }
    .mfp-iframe-scaler {
        width: 100%;
        height: 0;
        overflow: hidden;
        padding-top: 90%;
        iframe {
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            box-shadow: $mfp-shadow;
            background: $mfp-iframe-background;
        }
    }
}

// Image content type
@if $mfp-include-image-type {
    /* Main image in popup */
    img {
        &.mfp-img {
            width: auto;
            max-width: 100%;
            height: auto;
            display: block;
            line-height: 0;
            box-sizing: border-box;
            padding: $mfp-image-padding-top 0 $mfp-image-padding-bottom;
            margin: 0 auto;
        }
    }
    /* The shadow behind the image */
    .mfp-figure {
        line-height: 0;
        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: $mfp-image-padding-top;
            bottom: $mfp-image-padding-bottom;
            display: block;
            right: 0;
            width: auto;
            height: auto;
            z-index: -1;
            box-shadow: $mfp-shadow;
            background: $mfp-image-background;
        }
        small {
            color: $mfp-caption-subtitle-color;
            display: block;
            font-size: 12px;
            line-height: 14px;
        }
        figure {
            margin: 0;
        }
    }
    .mfp-bottom-bar {
        margin-top: -$mfp-image-padding-bottom + 4;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        cursor: auto;
    }
    .mfp-title {
        text-align: left;
        line-height: 18px;
        color: $mfp-caption-title-color;
        word-wrap: break-word;
        padding-right: 36px; // leave some space for counter at right side
    }
    .mfp-image-holder {
        .mfp-content {
            max-width: 100%;
        }
    }
    .mfp-gallery {
        .mfp-image-holder {
            .mfp-figure {
                cursor: pointer;
            }
        }
    }
    @if $mfp-include-mobile-layout-for-image {
        @media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
            /**
        * Remove all paddings around the image on small screen
        */
            .mfp-img-mobile {
                .mfp-image-holder {
                    padding-left: 0;
                    padding-right: 0;
                }
                img {
                    &.mfp-img {
                        padding: 0;
                    }
                }
                .mfp-figure {
                    // The shadow behind the image
                    &:after {
                        top: 0;
                        bottom: 0;
                    }
                    small {
                        display: inline;
                        margin-left: 5px;
                    }
                }
                .mfp-bottom-bar {
                    background: rgba(0, 0, 0, 0.6);
                    bottom: 0;
                    margin: 0;
                    top: auto;
                    padding: 3px 5px;
                    position: fixed;
                    box-sizing: border-box;
                    &:empty {
                        padding: 0;
                    }
                }
                .mfp-counter {
                    right: 5px;
                    top: 3px;
                }
                .mfp-close {
                    top: 0;
                    right: 0;
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                    background: rgba(0, 0, 0, 0.6);
                    position: fixed;
                    text-align: center;
                    padding: 0;
                }
            }
        }
    }
}

// Scale navigation arrows and reduce padding from sides
@media all and (max-width: 900px) {
    .mfp-arrow {
        transform: scale(0.75);
    }
    .mfp-arrow-left {
        transform-origin: 0;
    }
    .mfp-arrow-right {
        transform-origin: 100%;
    }
    .mfp-container {
        padding: $mfp-popup-padding-left-mobile;
    }
}

.mfp-vertical-top .mfp-preloader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 34px;
    height: 34px;
    z-index: -1;
}

.mfp-vertical-top .mfp-preloader::after,
.mfp-vertical-top .mfp-preloader::before {
    animation-fill-mode: both;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100%;
    content: '';
}

.mfp-vertical-top .mfp-preloader::before {
    background: $color-primary;
    height: 16px;
    width: 16px;
    top: 9px;
    left: 9px;
    animation: scale 1s 0s cubic-bezier(.09, .57, .49, .9) infinite;
}

.mfp-vertical-top .mfp-preloader::after {
    width: 34px;
    height: 34px;
    background: 0 0;
    border: 2px solid;
    border-color: rgba(255, 255, 255, .7) transparent;
    animation: rotate 1s 0s cubic-bezier(.09, .57, .49, .9) infinite;
    animation-duration: 1s;
}


/*====== Zoom-out effect ======*/

.mfp-zoom-out {
    /* start state */
    /* animate in */
    /* animate out */
}

.mfp-zoom-out .mfp-with-anim,
.mfp-zoom-out .mfp-iframe-scaler {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    transform: scale(1.3);
}

.mfp-zoom-out.mfp-bg {
    opacity: 0;
    transition: all 0.3s ease-out;
}

.mfp-zoom-out.mfp-ready .mfp-with-anim,
.mfp-zoom-out.mfp-ready .mfp-iframe-scaler {
    opacity: 1;
    transform: scale(1);
}

.mfp-zoom-out.mfp-ready.mfp-bg {
    opacity: 0.8;
}

.mfp-zoom-out.mfp-removing .mfp-with-anim,
.mfp-zoom-out.mfp-removing .mfp-iframe-scaler {
    transform: scale(1.3);
    opacity: 0;
}

.mfp-zoom-out.mfp-removing.mfp-bg {
    opacity: 0;
}

.mfp-vertical-top {
  /* start state */
  /* animate in */
  /* animate out */
}
.mfp-vertical-top .mfp-content {
  vertical-align: middle;
}
.mfp-vertical-top .mfp-with-anim {
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
          transform: translateY(-400px);
}
.mfp-vertical-top.mfp-bg {
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.mfp-vertical-top.mfp-ready .mfp-with-anim {
  opacity: 1;
          transform: translateY(0);
}
.mfp-vertical-top.mfp-ready.mfp-bg {
  opacity: 0.8;
}
.mfp-vertical-top.mfp-removing .mfp-with-anim {
          transform: translateY(200px);
  opacity: 0;
}
.mfp-vertical-top.mfp-removing.mfp-bg {
  opacity: 0;
}


@media screen and (max-width: 480px) and (orientation:portrait) {
    .mfp-iframe-scaler {
        padding-top: 160%;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0) scale(1)
    }
    50% {
        transform: rotate(180deg) scale(.6)
    }
    100% {
        transform: rotate(360deg) scale(1)
    }
}

@keyframes scale {
    30% {
        transform: scale(.3)
    }
    100% {
        transform: scale(1)
    }
}
