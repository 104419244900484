@include b(seo-text) {
    strong {
        color: $color-main;
        font-family: $font-raleway;
        font-size: 18px;
        font-weight: 900;
        text-transform: uppercase;
    }

    p {
        margin-top: 10px;
        color: $color-main;
        font-family: $font-roboto;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.5;
    }
}
