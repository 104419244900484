@include b(list-info) {
    @include e(item) {
        line-height: 1.4;

        &:not(:first-child) {
            margin-top: 15px;
        }
    }
    @include e(title) {
        margin-right: 3px;
        color: $color-info;
        font-weight: 500;
        text-transform: uppercase;
        @include media('<tablet-small') {
            font-size: 12px;
        }
    }
    @include e(value) {
        color: $color-black;
        font-weight: 500;
        text-transform: uppercase;
        @include media('<tablet-small') {
            font-size: 12px;
        }
    }
}
