@include b(footer) {
    padding: 20px 0;
    background-color: $color-main;
    background-image: url('%=static=%assets/footer/bg-footer.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    box-shadow: 0 -1px 0 $color-info;
    @include e(copyright) {
        order: 2;
        @include media('>=desktop') {
            margin-top: -100px;
            flex-basis: 100%;
        }
        @include media('<desktop') {
            text-align: center;
        }
    }
    @include e(main) {
        display: flex;
        flex-wrap: wrap;
        @include media('<desktop') {
            flex-direction: column;
            align-items: center;
        }
    }
    @include e(logo) {
        @include media('>=desktop') {
            flex-basis: 475px;
            flex-grow: 0;
        }
    }
    @include e(menu) {
        display: flex;
        margin-top: 10px;
        @include media('>=desktop') {
            flex: 1;
            justify-content: flex-end;
        }
    }
}

@include b(footer-menu) {
    @include media('>=desktop') {
        min-width: 1px;
        max-width: 675px;
    }
    @include e(list) {
        display: flex;
        margin: -10px -5px 0;
        flex-wrap: wrap;
        justify-content: center;
    }
    @include e(item) {
        padding: 10px 5px 0;
        @include media('>=desktop') {
            width: 33.33%;
        }

        &:last-child {
            @include media('>=desktop') {
                width: 33.33%;
                margin-left: auto;
            }
        }
    }
    @include e(link) {
        color: $color-white;
        font-family: $font-raleway;
        font-size: 11px;
        font-weight: 700;
        text-transform: uppercase;
        @include on-event() {
            color: $color-primary;
        }
        @include is(active) {
            color: $color-primary;
            pointer-events: none;
        }
    }
}
