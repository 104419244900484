@include b(navigation) {
    display: flex;
    @include media('<mobile-nav') {
        display: block;
        white-space: normal;
    }
    @include e(list) {
        display: inline-flex;
        position: relative;
        @include media('<desktop') {
            width: 100%;
            flex: 1 1 auto;
        }
        @include media('<mobile-nav') {
            display: flex !important;
            flex-direction: column;
        }

        & > .navigation__item {
            @extend %sep;
            padding: 0;

            &::after {
                display: none;
                height: 27px;
                background-color: $color-black;
                box-shadow: 1px 0 0 rgba(47, 49, 55, 0.9);
            }
            @include media('>=mobile-nav') {
                &::after {
                    display: block;
                }
            }
            @include media('<desktop') {
                flex: 1 1 auto;
            }
        }
    }
    @include e(link) {
        display: flex;
        width: 100%;
        padding: 12px 20px;
        color: $color-white;
        font-family: $font-raleway;
        font-size: 12px;
        font-weight: 700;
        line-height: 1;
        text-transform: uppercase;
        justify-content: center;
        align-items: center;
        @include on-event() {
            outline: 0;
            color: $color-primary;
        }
        @include is(active) {
            color: $color-primary;
            cursor: default;
            pointer-events: none;
        }
    }
    @include e(icon) {
        margin-right: 5px;
        font-size: 15px;
    }
    @include e(arrow) {
        margin-left: 5px;
        font-size: 11px;

        .is-open & {
            transform: rotate(-180deg);
        }
    }
    @include e(dropdown-toggle) {
        @extend .navigation__link;
        display: inline-block;
        outline: 0;
        font-size: 0;
        @include media('<mobile-nav') {
            display: none;
        }

        span {
            display: inline-block;
            vertical-align: middle;
            pointer-events: none;
        }

        .navigation__text {
            font-size: 12px;
        }

        .is-open &,
        .navigation__dropdown-wrapper:hover & {
            color: $color-primary;
            cursor: default;
        }
    }
    @include e(dropdown) {
        float: left;
        z-index: 110;
        left: 50%;
        min-width: 100%;
        padding: 8px 0;
        background-color: $color-main;
        background-image: url('%=static=%assets/header/bg-header.jpg');
        box-shadow: 0 7px 9px 1px rgba(0, 0, 0, 0.52);
        transform: translateX(-50%);
        @include media('<mobile-nav') {
            visibility: visible;
            position: static;
            transform: none;
        }

        &-wrapper {
            @extend %sep;
            margin: -7px 0;
            padding: 7px 0;

            &::after {
                height: 27px;
                background-color: $color-black;
                box-shadow: 1px 0 0 rgba(47, 49, 55, 0.9);
            }
            @include media('>=desktop') {
                margin: -16px 0;
                padding: 16px 0;
            }
            @include on-event() {
                .navigation__arrow {
                    transform: rotate(-180deg);
                }
            }
        }

        &::before {
            position: absolute;
            z-index: 2;
            top: -1px;
            width: 100%;
            height: 1px;
            background-color: $color-primary;
            content: '';
        }
    }
}

@include b(mobile-btn) {
    display: none;

    & + & {
        margin-left: 10px;
    }

    .btn {
        width: 40px;
        height: 40px;
        padding: 0;
        font-size: 20px;

        .icon {
            margin: auto;
        }
    }
    @include m(nav) {
        .nav-open & {
            .btn {
                background-color: $color-primary;
            }
        }
        @include media('<mobile-nav') {
            display: block;
            margin-right: 10px;
        }
    }
    @include m(user) {
        .user-open & {
            .btn {
                background-color: $color-primary;
            }
        }
        @include media('<mobile-nav') {
            display: block;
            margin-left: 10px;
        }
    }
}
