@include b(hint) {
    display: block;
    color: $color-light;
    font-family: $font-roboto;
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
    @include m(big) {
        font-size: 14px;
        font-weight: 700;
        line-height: 1.5;
    }
    &:not(:first-child) {
        margin-top: 10px;
    }
}
