@include b(media) {
    display: flex;
    position: relative;
    form {
        .btn-group {
            margin-top: 10px;
        }
    }
    @include media('<tablet-small') {
        flex-direction: column;
        text-align: center;

        .btn-group {
            justify-content: center;
        }
    }
    @include e(header) {
        margin-bottom: 5px;
        color: $color-info;
        font-size: 11px;
        line-height: 1.5;
    }
    @include e(content) {
        flex: 1 1 auto;
    }
    @include e(avatar) {
        overflow: hidden;
        position: relative;
        width: 60px;
        height: 60px;
        margin-right: 15px;
        flex-shrink: 0;

        img {
            display: block;
            width: 60px;
            height: 60px;
        }
        @include media('<tablet-small') {
            margin: auto;
            margin-bottom: 15px;
        }
    }
    @include e(user) {
        color: $color-primary;
        font-weight: 500;
        @include on-event() {
            text-decoration: underline;
        }
    }
    @include e(body) {
        p {
            line-height: 1.4;
            a {
                color: $color-primary;
                font-weight: 500;
                @include on-event() {
                    text-decoration: underline;
                }
            }
        }
    }
    @include e(bg) {
        display: block;
        width: 100%;
        margin-bottom: 10px;
    }
    @include e(footer) {
        margin-top: 15px;
    }
    @include e(checkbox) {
        position: absolute;
        z-index: 2;
        top: 5px;
        right: 5px;
    }
    @include e(item) {
        &:not(:first-child) {
            margin-top: 20px;
            padding-top: 20px;
            border-top: 1px solid $color-gray;
        }
    }
}
