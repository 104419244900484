@include b(popup) {
    min-width: 280px;
    max-width: 450px;
    margin: 0 auto;
    @include m(big) {
        max-width: 820px;
    }
    @include e(dialog) {
        overflow: hidden;
        position: relative;
        padding: 20px;
        border: 1px solid $color-gray;
        border-radius: $border-radius;
        background-color: $color-white;
        box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.82);
        @include media('<tablet-small') {
            padding: 15px;
        }
    }
    @include e(heading) {
        display: flex;
        margin-bottom: 8px;
        align-items: center;

        &-icon {
            margin-right: 6px;
            color: $color-light;
            font-size: 30px;
        }

        &-title {
            color: $color-black;
            font-family: $font-raleway;
            font-size: 18px;
            font-weight: 900;
            text-transform: uppercase;
            @include media('<tablet-small') {
                font-size: 13px;
            }
        }

        &-tab {
            @extend %underline;
            color: $color-info;
            font-family: $font-raleway;
            font-size: 18px;
            font-weight: 900;
            text-transform: uppercase;
            cursor: pointer;

            &::before {
                top: calc(100% + 2px);
                height: 2px;
                background-color: $color-primary;
                transition: none;
            }

            &:not(:first-child) {
                margin-left: 30px;
            }
            @include media('<tablet-small') {
                font-size: 13px;
            }
            @include on-event() {
                outline: 0;
                color: $color-primary;
            }
            @include is(active) {
                color: $color-primary;
                pointer-events: none;

                &::before {
                    transform: scale(1);
                }
            }
        }

        strong {
            color: $color-primary;
        }
    }
    @include e(close) {
        position: absolute;
        z-index: 1;
        top: 10px;
        right: 10px;
        outline: 0;
        color: $color-light;
        font-size: 45px;
        @include on-event() {
            color: $color-primary;
        }
    }
    @include e(hint) {
        padding-top: 7px;
    }
    @include e(content) {
        padding: 20px;
        @include media('<tablet-small') {
            padding: 0;
        }
    }
}
