@include b(validate) {
    display: none;
    margin-top: 7px;
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    @include m(error) {
        color: $color-primary;
    }
    @include m(success) {
        color: $color-success;
    }
}

.message-error,
.message-success {
    margin: 0 0 10px;
    padding: 10px;
    border: 1px solid #f5aca6;
    border-radius: 5px;
    color: #555;
    background-color: #ffecec;
    font-size: 13px;
    font-weight: 300;
}

.message-success {
    border: 1px solid #a6ca8a;
    background-color: #e9ffd9;
}
