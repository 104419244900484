@include b(comments) {
    @include e(form) {
        margin-bottom: 20px;

        .btn {
            margin-left: 10px;
            align-self: flex-start;
            @include media('<tablet-small') {
                margin-top: 10px;
                margin-left: 0;
            }
        }
    }
    @include e(item) {
        &:not(:first-child) {
            margin-top: 20px;
            padding-top: 20px;
            border-top: 1px solid $color-gray;
        }
        @include is(dim) {
            opacity: 0.5;
        }
    }
}

@include b(comment) {
    display: flex;
    align-items: flex-start;
    @include media('<tablet-small') {
        flex-direction: column;
    }
    @include e(avatar) {
        overflow: hidden;
        position: relative;
        width: 60px;
        height: 60px;
        margin-right: 10px;
        flex-shrink: 0;
        @include media('<tablet-small') {
            margin: auto;
            margin-bottom: 15px;
        }
    }
    @include e(link) {
        display: block;
    }
    @include e(img) {
        img {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    @include e(header) {
        display: flex;
        align-items: center;
        @include media('<tablet-small') {
            justify-content: center;
        }
    }
    @include e(user) {
        display: block;
        color: $color-primary;
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        text-transform: uppercase;
        @include on-event() {
            text-decoration: underline;
        }
    }
    @include e(time) {
        display: flex;
        margin-left: 10px;
        color: $color-info;
        font-size: 11px;
        line-height: 1;
        align-items: center;
    }
    @include e(body) {
        line-height: 1.8;
        @include media('<tablet-small') {
            text-align: center;
        }
    }
    @include e(rating) {
        margin-left: auto;
        align-self: center;
        @include media('<tablet-small') {
            margin: auto;
            margin-top: 10px;
        }

        .rating__btn-icon {
            font-size: 20px;
        }

        .rating__btn--dislike {
            margin-left: 5px;
        }
    }
}
