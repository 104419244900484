@include b(player) {
    &__holder {
        position: relative;
        width: 100%;
        height: auto;
    }
}
.player {
    background: #000;
}
.player .embed-wrap {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
}
.player .player-wrap {
    position: relative;
    width: 100%;
    height: auto;
}
.player .player-wrap .poster {
    width: 100%;
    height: auto;
}
.player .embed-wrap iframe,
.player .player-wrap #kt_player,
.player .player-wrap #kt_player_internal {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
}
.player .player-wrap #kt_player.is-fullscreen {
    position: fixed !important;
}
.player .loading {
    display: block;
    padding: 50px 20px;
    color: #999;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    text-align: center;
}
.no-player {
    display: block;
    overflow: hidden;
    position: relative;
}
.no-player img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    filter: alpha(opacity=20);
}
.no-player .message {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    padding: 20px;
    color: #999;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    text-align: center;
    transform: translateY(-50%);
}
.no-player .message em {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
}
.no-player .message a {
    color: #fff;
}
.no-player .message a:hover {
    color: #f56c08;
}
.no-player .message form {
    display: block;
    padding: 20px 0 0;
}
.no-player .btn-play {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url('../player/skin/img/play_white.png') 50% 50% no-repeat;
}

/* related videos in player */
.player-related-videos {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 5px 10px 30px 10px;
    background: #000000;
}
.player-related-videos .player-related-videos-container {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
}
.player-related-videos .player-related-videos-item {
    display: inline-block;
    position: relative;
    margin-top: 5px;
    vertical-align: middle;
}
.player-related-videos .player-related-videos-item .title {
    display: block;
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 52px;
    padding: 5px;
    color: #ffffff;
    background: -moz-linear-gradient(top, rgba(12, 12, 12, 0.8) 0px, transparent 50px);
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0px, rgba(12, 12, 12, 0.8)),
        color-stop(50px, transparent)
    );
    background: -webkit-linear-gradient(top, rgba(12, 12, 12, 0.8) 0px, transparent 50px);
    background: -o-linear-gradient(top, rgba(12, 12, 12, 0.8) 0px, transparent 50px);
    background: -ms-linear-gradient(top, rgba(12, 12, 12, 0.8) 0px, transparent 50px);
    background: linear-gradient(to bottom, rgba(12, 12, 12, 0.8) 0px, transparent 50px);
    text-align: left;
}
.player-related-videos .player-related-videos-item .duration {
    display: block;
    position: absolute;
    right: 5px;
    bottom: 5px;
    padding: 2px 5px;
    color: #ffffff;
    background: rgba(12, 12, 12, 0.8);
}
.no-touchevents .player-related-videos .player-related-videos-item .title,
.no-touchevents .player-related-videos .player-related-videos-item .duration {
    display: none;
}
.no-touchevents .player-related-videos .player-related-videos-item:hover .title,
.no-touchevents .player-related-videos .player-related-videos-item:hover .duration {
    display: block;
}
