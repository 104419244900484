@include b(tabs) {
}

@include b(tabs-nav) {
    display: flex;
    margin: -15px -15px 0;
    margin-bottom: 17px;
    align-items: center;
    @include media('<tablet') {
        margin: -7px -7px 0;
        margin-bottom: 15px;
    }
    @include media('<520px') {
        justify-content: center;
    }
    @include e(item) {
        padding: 15px 15px 0;
        line-height: 1;
        @include media('<tablet') {
            padding: 7px 7px 0;
        }
    }
    @include e(btn) {
        @extend %underline;
        display: inline-block;
        color: $color-info;
        font-family: $font-roboto;
        font-size: 0;
        font-weight: 700;
        line-height: 1;
        white-space: nowrap;
        text-transform: uppercase;

        &::before {
            top: calc(100% + 3px);
            height: 2px;
            background-color: $color-primary;
            transition: none;
        }
        @include on-event() {
            outline: 0;
            color: $color-primary;
        }
        @include is(active) {
            outline: 0;
            color: $color-primary;

            &::before {
                transform: scale(1);
            }
        }
    }
    @include e(text) {
        display: inline-block;
        margin: 3px 0 0;
        font-size: 16px;
        vertical-align: middle;
        @include media('<tablet') {
            font-size: 14px;
        }
        @include media('<520px') {
            display: none;
        }
    }
    @include e(icon) {
        display: inline-block;
        margin-right: 5px;
        font-size: 25px;
        vertical-align: middle;
        @include media('<520px') {
            margin-right: 0;
        }
    }
}
