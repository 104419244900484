@include b(label) {
    display: block;
    margin-bottom: 7px;
    color: $color-info;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    text-transform: uppercase;
    cursor: pointer;
    &::after {
        content: ':';
    }
    @include is(required) {
        &::after {
            content: ' (*):';
        }
    }
}
