@include b(cms-content) {
    section {
        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 15px;
        line-height: 1.2;
        text-transform: uppercase;
    }
    h1 {
        font-size: 1.7em;
    }
    h2 {
        font-size: 1.5em;
    }
    h3 {
        font-size: 1.3em;
    }
    h4 {
        font-size: 1.2em;
    }
    h5 {
        font-size: 1.1em;
    }
    h6 {
        font-size: 1em;
    }
    p {
        color: $color-black;
        font-size: 14px;
        line-height: 1.6;
        &:not(:first-child) {
            margin-top: 15px;
        }
    }
    mark,
    .danger {
        color: $color-primary;
        background-color: transparent;
        font-weight: 700;
        font-style: normal;
    }
    .danger {
        font-size: 20px;
        line-height: 1.2;
    }
    a {
        @extend %underline;
        color: $color-primary;
        font-weight: 700;
        &::before {
            background-color: $color-primary;
        }
    }
    ul {
        padding-left: 15px;
    }
    ul,
    li {
        list-style-position: outside;
        list-style-type: disc;
    }
    ul li {
        margin-bottom: 10px;
    }
    p.big span {
        color: $color-primary;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
        font-size: 30px;
        font-weight: 700;
        text-transform: uppercase;
    }
}
