@include b(gallery-preview) {
    overflow: hidden;
    position: relative;
    @include media('>=tablet-small') {
        height: 550px;
    }
    .swiper-container {
        height: 100%;
    }
    @include e(item) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    img {
        display: block;
        @include media('>=tablet-small') {
            height: 100%;
        }
    }
}

@include b(gallery-images) {
    position: relative;
    height: 485px;
    @include media('<tablet') {
        height: 385px;
    }
    @include media('<tablet-small') {
        height: 185px;
    }
    @include e(card) {
        position: relative;
        min-height: 100px;
        &::after {
            display: none;
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 6px solid $color-primary;
            background-color: rgba($color-black, 0.6);
            content: '';
            pointer-events: none;
        }
        @include is(active) {
            &::after {
                display: block;
            }
        }
    }
    @include e(img) {
        display: block;
    }

    .swiper-slide {
        display: flex;
        margin: -10px -5px 0;
        flex-wrap: wrap;
    }
    @include e(item) {
        padding: 10px 5px 0;
    }

    .swiper-container {
        width: 100%;
        height: 100%;
    }
}
