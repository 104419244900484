.select2-selection--single {
    background-color: $color-white;
    height: 42px;
    outline: 0;
    border: 1px solid $color-gray;
    border-radius: 2px;
    .select2-selection__text {
        display: inline-block;
        vertical-align: middle;
        line-height: 1;
    }
    .select2-selection__rendered {
        color: $placeholder-color;
        line-height: 42px;
        font-size: 13px;
        padding-left: 15px;
        padding-right: 50px;
    }

    .select2-selection__clear {
        cursor: pointer;
        float: right;
        font-weight: 700;
    }

    .select2-selection__placeholder {
        color: $placeholder-color;
    }

    .select2-selection__arrow {
        height: 40px;
        position: absolute;
        width: 20px;
        top: 1px;
        right: 15px;
        line-height: 40px;
        text-align: center;
        b {
            height: 8px;
            width: 5px;
            display: inline-block;
            position: relative;
            transform-origin: 40% 45%;
            &::after {
                position: absolute;
                top: 0;
                left: 0;
                width: 5px;
                height: 5px;
                line-height: 5px;
                border-top: 1px solid $color-light;
                border-right: 1px solid $color-light;
                content: '';
                backface-visibility: hidden;
                transform-origin: center;
                bottom: 0;
                transform: rotate(135deg);
            }
        }
    }
}

&[dir="rtl"] {
    .select2-selection--single {
        .select2-selection__clear {
            float: left;
        }

        .select2-selection__arrow {
            left: 1px;
            right: auto;
        }
    }
}

&.select2-container--disabled {
    .select2-selection--single {
        background-color: #eee;
        cursor: default;

        .select2-selection__clear {
            display: none;
        }
    }
}

&.select2-container--open {
    .select2-selection__arrow {
        b {
            transform: rotate(-180deg);
            &::after {
                border-color: $color-primary;
            }
        }
    }
    .select2-selection--single {
        border: 1px solid $color-primary;
        border-bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}
