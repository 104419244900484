// -----------------------------------------------------------------------------
// This file contains typography styles.
// -----------------------------------------------------------------------------

html {
    font-size: $font-size--root;
}

body {
    font-family: $font-roboto;
    font-size: $font-size--base;
    line-height: $line-height--base;
}
