@include b(header) {
    position: relative;
    background-color: $color-main;
    background-image: url('%=static=%assets/header/bg-header.jpg');
    box-shadow: 0 1px 0 $color-info;
    @include media('>=mobile-nav') {
        padding: 7px 0;
    }
    @include e(main) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include media('<desktop') {
            flex-wrap: wrap;
        }
    }
    @include e(mobile) {
        flex-shrink: 0;
        @include media('<mobile-nav') {
            display: flex;
            width: 100%;
            padding: 10px 0;
            align-items: center;
            flex-shrink: 1;
            flex-basis: 100%;
            justify-content: space-between;
        }
    }
    @include e(orientation) {
        margin-top: -8px;
        @include media('<mobile-nav') {
            display: none;
        }
    }
    @include e(search) {
        padding: 0 10px;
        @include media('>=mobile-nav') {
            min-width: 200px;
            padding: 0;
            flex: 1 1 auto;
        }
    }
    @include e(community) {
        display: flex;
        position: relative;
        width: 100%;
        margin-left: 25px;
        padding: 12px 20px;
        color: $color-white;
        font-family: $font-raleway;
        font-size: 12px;
        font-weight: 700;
        line-height: 1;
        text-transform: uppercase;
        justify-content: center;
        align-items: center;
        @include media('<mobile-nav') {
            display: none;
        }
        @include on-event() {
            outline: 0;
            color: $color-primary;
        }
        @include is(active) {
            color: $color-primary;
            cursor: default;
            pointer-events: none;
        }

        &::after {
            position: absolute;
            top: 50%;
            left: 0;
            width: 1px;
            height: 27px;
            background-color: $color-black;
            box-shadow: 1px 0 0 rgba(47, 49, 55, 0.9);
            content: '';
            transform: translateY(-50%);
        }

        &-icon {
            margin-right: 5px;
            font-size: 15px;
        }
    }
    @include e(additional) {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        @include media('<mobile-nav') {
            visibility: hidden;
            flex-direction: column;
            position: fixed;
            z-index: 100;
            top: 62px;
            left: 0;
            width: 100%;
            height: calc(100% - 62px);
            margin-top: 0;
            background-color: $color-main;
            background-image: url('%=static=%assets/header/bg-header.jpg');
            transition: opacity $transition-speed, visibility $transition-speed, transform $transition-speed;
            transform: translateX(100%);
            align-items: stretch;

            .user-open & {
                visibility: visible;
                transform: translateX(0);
            }
        }
    }
    @include e(logo) {
        @include media('<mobile-nav') {
            margin: 0 auto;
        }
    }
    @include e(language) {
        @include media('>=mobile-nav') {
            margin-left: 15px;
            flex-shrink: 0;
        }
        @include media('<mobile-nav') {
            margin-bottom: 5px;

            .language__button {
                width: 100%;
                background-color: $bg-dark;

                &[aria-expanded='true'] {
                    color: $color-white;
                    background-color: $color-primary;
                }
            }
        }
    }
    @include e(member) {
        flex-shrink: 0;

        .btn-group {
            @include media('<mobile-nav') {
                flex-direction: column;

                .btn {
                    width: 100%;
                }
            }
        }
    }
    @include e(navigation) {
        width: 100%;
        max-width: 700px;
        @include media('<desktop') {
            max-width: 100%;
            margin-top: 10px;
            order: 2;
        }
        @include media('<mobile-nav') {
            display: block;
            overflow-x: hidden;
            overflow-y: auto;
            position: relative;
            z-index: 2;
            height: 100%;
            -webkit-overflow-scrolling: touch;
        }
    }
    @include e(wrap) {
        display: flex;
        min-width: 1px;
        flex: 1 1 auto;
        align-items: center;
        @include media('<desktop') {
            margin-top: 10px;
            margin-left: 0;
            flex-wrap: wrap;
            order: 3;
            flex-basis: 100%;
        }
        @include media('<mobile-nav') {
            visibility: hidden;
            position: fixed;
            z-index: 100;
            top: 62px;
            left: 0;
            width: 100%;
            height: calc(100% - 62px);
            margin-top: 0;
            background-color: $color-main;
            background-image: url('%=static=%assets/header/bg-header.jpg');
            transition: opacity $transition-speed, visibility $transition-speed, transform $transition-speed;
            transform: translateX(-100%);
            align-items: stretch;
            flex-flow: column nowrap;

            .nav-open & {
                visibility: visible;
                transform: translateX(0);
            }
        }
    }
    @include e(slogan) {
        color: $color-white;
        text-transform: uppercase;
        margin-left: 48px;
        font-size: 12px;
        font-weight: 700;
        margin-top: -8px;
        span {
            color: $color-primary;
        }
    }

    .dropdown {
        @include media('>=mobile-nav') {
            margin: -7px 0;
            padding: 7px 0;
        }
        @include media('>=desktop') {
            margin: -15px 0;
            padding: 15px 0;
        }
        @include media('<mobile-nav') {
            width: 100%;
        }
    }

    .dropdown__content {
        @include media('>=desktop') {
            top: calc(100% - 4px);
        }
        @include media('<mobile-nav') {
            display: none;
            position: static;
            left: 0;
            transform: none;
        }
    }

    & .open .dropdown__content {
        display: block;
    }
}

@include b(stuck) {
    position: fixed;
    z-index: 101;
    top: 0;
    left: 0;
    width: 100% !important;
}
