@include b(dropdown) {
    display: inline-block;
    position: relative;
    @include e(content) {
        visibility: hidden;
        float: left;
        position: absolute;
        z-index: 1000;
        top: 100%;
        left: 0;
        min-width: 100%;
        padding: 0;
        border: 1px solid $color-gray;
        border-radius: $border-radius;
        opacity: 0;
        background-color: $color-white;
        background-clip: padding-box;
        box-shadow: 0 6px 6px rgba(35, 31, 32, 0.11);
        @include at('dark') {
            padding: 15px;
            border: 0;
            background-color: $bg-dark;
            box-shadow: none;
        }
        @include at('arrow') {
            top: calc(100% + 6px);
            padding: 5px 0;
        }

        .triangle {
            overflow: hidden;
            position: absolute;
            z-index: 1;
            top: -12px;
            left: 50%;
            transform: translateX(-50%);
        }
        @include e(list) {
            @include m(row) {
                display: flex;
                justify-content: center;
            }
        }
        @include e(item) {
            display: flex;
            padding: 10px;
            color: $color-light;
            font-family: $font-raleway;
            font-size: 11px;
            font-weight: 700;
            line-height: 1;
            white-space: nowrap;
            text-transform: uppercase;
            align-items: center;
            justify-content: center;
            @include on-event() {
                color: $color-primary;
            }
            &.is-hidden {
                display: none;
            }
            @include at('dark') {
                padding: 10px;
                color: $color-white;
                font-size: 12px;
                justify-content: flex-start;
            }
            @include at('arrow') {
                padding: 10px 15px;
            }
        }
        @include e(icon) {
            margin-right: 5px;
            font-size: 16px;
        }
        @include m(right) {
            right: 0;
            left: auto;

            .triangle {
                right: 5px;
                left: auto;
            }
        }
        @include m(left) {
            right: auto;
            left: 0;
        }
        @include m(center) {
            left: 50%;
            transform: translateX(-50%);
        }

        .open > & {
            visibility: visible;
            margin-top: -2px;
            border-top-color: transparent;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            opacity: 1;
        }
    }
    @include on-event() {
        .dropdown__content {
            visibility: visible;
            margin-top: -2px;
            border-top-color: transparent;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            opacity: 1;
        }

        .btn__arrow,
        .language__arrow {
            transform: rotate(-180deg);
        }
    }
    @include m(arrow) {
        &.open > .dropdown__content {
            border-top-color: $color-gray;
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
        }
        @include on-event() {
            .dropdown__content {
                border-top-color: $color-gray;
                border-top-left-radius: $border-radius;
                border-top-right-radius: $border-radius;
            }
        }
    }
    .btn--border {
        min-width: 140px;
    }
    .dropdown-backdrop {
        position: fixed;
        z-index: 990;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}
