@include b(captcha) {
    @include e(hint) {
        color: $color-info;
        font-size: 13px;
        font-weight: 400;
        display: block;
        margin-bottom: 7px;
    }
    @include e(img) {
        margin-bottom: 10px;
        width: 165px;
        height: 68px;
        display: block;
    }
}
