@include b(logo) {
    display: flex;
    align-items: center;
    @include e(image) {
        margin-right: 3px;

        img {
            display: block;
        }
    }
    @include e(text) {
        display: block;
        color: $color-white;
        font-family: $font-raleway;
        font-size: 24px;
        font-weight: 900;
        line-height: 1;
        white-space: nowrap;
        text-transform: uppercase;
        @include media('<mobile-nav') {
            font-size: 20px;
        }

        strong {
            color: $color-primary;
        }
    }
}
