@include b(search) {
    @include e(frame) {
        display: flex;
        position: relative;
    }
    @include e(icon) {
        display: flex;
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        width: 50px;
        height: 40px;
        color: $color-main;
        font-size: 20px;
        align-items: center;
        justify-content: center;
        @include on-event() {
            outline: 0;
            color: $color-white;
        }
    }
    @include e(field) {
        display: block;
        position: relative;
        z-index: 1;
        width: 100%;
        height: 40px;
        padding: 0 50px 0 15px;
        border-radius: $border-radius;
        color: $color-white;
        background-color: $color-info;
        font-family: $font-roboto;
        font-size: 12px;
        font-weight: 400;
        @include placeholder() {
            color: $color-white;
        }

        &:focus {
            outline: 0;
        }
    }
}
